import { OrganizationAccountsCacheData, SavedOrganization } from '../../types/sta';
import { localStorageService } from '../../services/localStorageService';
import { ORGANIZATION_ACCOUNTS_DATA } from '../../constants/storageKeys';
import { ADMIN_APP_PROD_REDIRECT_URI, CLIENT_WEB_APP_PROD_URL } from '../../constants/strings';
import { inflate } from 'pako';
import { AuthRegion } from '../../types';
import moment from 'moment-timezone';
import { IsFeatureFlagEnabled } from '../FeatureFlagManager';
import { FeatureFlagResult } from '../FeatureFlags';
import { getParsedAuthRegion } from '../localStorageHelper';

export const getCurrentLoggedInAccount = () => {
  const cacheStorage = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  return cacheStorage?.savedOrganizations?.[0];
};

export const checkForLoggedOutAccounts = (cachedData: OrganizationAccountsCacheData | null) => {
  if (!cachedData) {
    return false;
  }
  return cachedData?.savedOrganizations.some((account) => !account.accessToken);
};

export const isRefreshTokenValid = (account: SavedOrganization) => {
  return !!account.refreshToken;
};

export const isAccessTokenValid = (account: SavedOrganization) => {
  if (!account.accessToken) return false;

  if (!account.accessTokenExpiresAt) return false;

  let accessTokenExpiry = new Date(account.accessTokenExpiresAt);

  let now = new Date();

  return accessTokenExpiry > now;
};

export const getCookieValue = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=hypercare.com; Secure;';
};

export const checkSTAFeatureFlag = () => {
  try {
    const storage = localStorage.getItem('staFlag');

    if (!storage) {
      return false;
    }

    const parsedFlag = storage && JSON.parse(storage);

    return parsedFlag === true;
  } catch (error) {
    console.error('Error checking STA feature flag:', error);
    return false;
  }
};

export const getRedirectUriForAdmin = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return ADMIN_APP_PROD_REDIRECT_URI;
    case 'staging':
      return 'https://admin.app.release.hypercare.com/login';
    default:
      return ADMIN_APP_PROD_REDIRECT_URI;
  }
};

export const getClientWebAppUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return CLIENT_WEB_APP_PROD_URL;
    case 'staging':
      return 'https://app.release.hypercare.com';
    default:
      return CLIENT_WEB_APP_PROD_URL;
  }
};

export const formatSavedOrgPayloadForTransfer = (savedOrg: SavedOrganization, currentRegion: AuthRegion) => {
  const payload = {
    selectedAccount: savedOrg,
    clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
    regionCode: currentRegion,
  };
  return payload;
};

export const decodeObjectToBase64 = <T extends object>(obj: T) => {
  if (obj) {
    return window.btoa(JSON.stringify(obj));
  }
};

export const addDataToSavedAccounts = (
  cachedData: OrganizationAccountsCacheData,
  incomingAccounts: OrganizationAccountsCacheData,
) => {
  const newData: SavedOrganization[] = [];

  for (let accs of incomingAccounts.savedOrganizations) {
    const isAccAlreadyInCache = cachedData.savedOrganizations.findIndex((acc) => acc.user.id === accs.user.id);

    if (isAccAlreadyInCache !== -1) {
      cachedData.savedOrganizations[isAccAlreadyInCache] = accs;
    } else {
      newData.push(accs);
    }
  }

  cachedData.savedOrganizations = cachedData.savedOrganizations.concat(newData);
  cachedData.selectedAccountUserId = incomingAccounts.selectedAccountUserId
    ? incomingAccounts.selectedAccountUserId
    : '';
  localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, cachedData);
  return cachedData;
};

export const base64GzipDecode = (compressedBase64) => {
  // Convert the base64 string back to gzipped data (a Uint8Array)
  const binaryString = atob(compressedBase64);
  const charData = binaryString.split('').map((char) => char.charCodeAt(0));
  const gzippedData = new Uint8Array(charData);

  // Decompress the gzipped data
  const decompressedData = inflate(gzippedData);

  // Convert the decompressed data back to a string
  const textDecoder = new TextDecoder();
  const originalString = textDecoder.decode(decompressedData);

  return originalString;
};

export const logoutAllSavedAccounts = ({ savedOrganizations }: OrganizationAccountsCacheData) => {
  const updatedOrganizations = savedOrganizations.map(({ accessToken, ...rest }) => ({
    ...rest,
    accessToken: null,
    refreshToken: null,
    accessTokenExpiresAt: null,
  }));

  localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, {
    selectedAccountUserId: '',
    savedOrganizations: updatedOrganizations,
  });

  return updatedOrganizations;
};

export const isEmail = (input: string): boolean => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(input);
};

export const isPhoneNumber = (input: string): boolean => {
  const phonePattern = /^\+?[0-9]{1,4}[-\s]?(\(?[0-9]{1,3}\)?[-\s]?)?[0-9]{1,4}[-\s]?[0-9]{1,4}[-\s]?[0-9]{1,4}$/;
  return phonePattern.test(input);
};

export const checkInputType = (input: string): 'email' | 'phone' | 'neither' => {
  if (isEmail(input)) {
    return 'email';
  } else if (isPhoneNumber(input)) {
    return 'phone';
  } else {
    return 'neither';
  }
};

export const formatLastLoginTime = (lastLogin: string) => {
  return moment(lastLogin).tz(moment.tz.guess()).format('DD/MM/YYYY, HH:mm:ss (z)');
};

export const isSTAFeatureFlagEnabled = () => {
  const auth_region = getParsedAuthRegion();

  if (auth_region === 'US') {
    return IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlagUS);
  }

  return IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlagCa);
};
