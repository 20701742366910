import gql from 'graphql-tag';
import {
  FullOrganizationUserResultFragment,
  FullOrganizationUserDirSyncResultFragment,
} from '../fragment/FullOrganizationUserResultFragment';

export const FETCH_USER_PROFILE = gql`
  query FetchUserProfile(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $isProfileAddressLabelFlagEnabled: Boolean!
    $isSTAFlagEnabled: Boolean!
  ) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserResultFragment
            }
          }
          ... on AdminSiteQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserResultFragment
            }
          }
          ... on AdminDepartmentQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserResultFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationUserResultFragment}
`;

export const FETCH_USER_PROFILE_DIR_SYNC = gql`
  query FetchUserProfileWithDirSyncFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $isProfileAddressLabelFlagEnabled: Boolean!
  ) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
          ... on AdminSiteQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
          ... on AdminDepartmentQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
        }
      }
    }
  }
  ${FullOrganizationUserDirSyncResultFragment}
`;

export const FETCH_USER_PROFILE_DIR_SYNC_STA = gql`
  query FetchUserProfileWithDirSyncFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $isProfileAddressLabelFlagEnabled: Boolean!
  ) {
    adminQuery {
      __typename
      ... on UserNotAdminForScope {
        __typename
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on OrganizationalUnitNotFoundError {
            __typename
            message
          }
          ... on AdminOrganizationQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
                __typename
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
          ... on AdminSiteQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
                __typename
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
          ... on AdminDepartmentQuery {
            __typename
            member(id: $userId) {
              __typename
              ... on UserNotFoundError {
                message
                __typename
              }
              ...FullOrganizationUserDirSyncResultFragment
            }
          }
          __typename
        }
        __typename
      }
    }
  }

  fragment FullOrganizationUserDirSyncResultFragment on FullOrganizationUserResult {
    ... on PublicUser {
      ...GeneralUserFragment
      __typename
    }
    ... on Node {
      id
      __typename
    }
    ... on FullOrganizationMember {
      ...FullOrganizationMemberDirSyncFragment
      __typename
    }
    __typename
  }

  fragment GeneralUserFragment on GeneralUser {
    username
    firstname: firstName
    lastname: lastName
    __typename
  }

  fragment FullOrganizationMemberDirSyncFragment on FullOrganizationMember {
    id
    username
    firstname: firstName
    lastname: lastName
    joinDate
    licenseStartTime
    invite {
      status
      __typename
    }
    memberStatus
    licenseExpiryTime
    isDirectorySynced
    isSSOEnabled
    profilePic: avatar {
      url
      __typename
    }
    role
    addresses {
      ...AddressFragmentDirSync
      __typename
    }
    scopes {
      ...OrganizationScopeFragment
      __typename
    }
    loginMethods
    __typename
  }

  fragment AddressFragmentDirSync on Address {
    address
    type
    label @include(if: $isProfileAddressLabelFlagEnabled)
    access
    syncStatus
    id
    __typename
  }

  fragment OrganizationScopeFragment on OrganizationScope {
    organizationId
    siteId
    departmentId
    status
    __typename
  }
`;
