import { CANADA_REGION_CODE, EUROPE_REGION_CODE, UNITED_STATES_REGION_CODE } from 'src/constants/strings';
import { getParsedAuthRegion } from 'src/utils/localStorageHelper';
import { checkSTAFeatureFlag } from './sta/util';

const getApiEndpoint = () => {
  const region = getParsedAuthRegion();

  let endPointUrl;

  switch (region) {
    case CANADA_REGION_CODE:
      endPointUrl = process.env.REACT_APP_API_URL;
      break;
    case EUROPE_REGION_CODE:
      endPointUrl = process.env.REACT_APP_API_URL_EU;
      break;
    case UNITED_STATES_REGION_CODE:
      endPointUrl = process.env.REACT_APP_API_URL_US;
      break;
    default:
      endPointUrl = process.env.REACT_APP_API_URL;
  }

  // let port = process.env.REACT_APP_API_PORT ? String(process.env.REACT_APP_API_PORT) : '';
  let port = checkSTAFeatureFlag() ? '3000' : '';
  const url = String(endPointUrl);

  return port ? `${url}:${port}` : url;
};

export default getApiEndpoint;
