import styled from 'styled-components';

const itemHeight = 40;

export const PageContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  overflow-x: hidden;
  background-color: #ffd8dd;
`;

export const AccountSwitchLogo = styled.img`
  height: 100%;
  width: 100%;
`;

export const AccountSwitchLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
`;

export const DropdownToggler = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
  justify-content: center;
  margin-left: 24px;
`;

export const DropdownMenu = styled.ul`
  list-style: none;
  padding: 8px 4px 8px 8px;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 50px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  margin-top: 50px;
  min-height: ${itemHeight}px;
  max-height: 600px;
  overflow-y: auto;

  cursor: pointer;

  width: 400px;
`;
