import gql from 'graphql-tag';
import { ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type UpdateProfileFieldMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        profileField: {
          update: ProfileFieldFragmentType;
        };
      };
    };
  };
};

export const ADMIN_UPDATE_PROFILE_FIELD_MUTATION = gql`
  mutation UpdateProfileFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $uniqueFieldId: ID!
    $fieldData: ProfileFieldInput!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              ... on FullOrganizationMemberMutation {
                profileField(id: $uniqueFieldId) {
                  update(fieldData: $fieldData) {
                    ... on Node {
                      id
                    }
                    ... on ProfilePhoneField {
                      ...ProfileFieldFragment
                      phoneNumber
                      placeholder
                    }
                    ... on ProfileTextField {
                      ...ProfileFieldFragment
                      text
                      placeholder
                    }
                    ... on ProfileDropdownField {
                      options
                      selectedOption
                    }
                    ... on ProfileNumericField {
                      value
                    }
                    ... on ProfileLinkField {
                      url
                    }
                    ... on ProfileEmailField {
                      email
                    }
                    ... on ProfileDateField {
                      date
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment ProfileFieldFragment on ProfileField {
    fieldId
    label
    labelOptions
    visibility
    isSynced
    isEditable
    isRemovable
    isRequired
    validationRules {
      regex
    }
  }
`;
