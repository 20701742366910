import React from 'react';
import store from 'src/redux/store';
import styled from 'styled-components';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PrimaryButton, SecondaryButton } from 'src/components/shared/HypercareComponents';
import { APPROVE } from 'src/constants/inviteUserTypes';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { isSTAFeatureFlagEnabled } from '../../utils/sta/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: '24px 24px 16px 24px',
      padding: '0px',
    },
    dialogIconButton: {
      marginTop: '-4px',
      left: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      float: 'right',
    },
    dialogIconContainer: {
      alignSelf: 'center',
    },
    dialogContent: {
      padding: '0px 24px 24px 24px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    dialogDescription: {
      fontFamily: 'open sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: '#4A4A4A',
      marginBottom: '0px',
    },
    dialogAction: {
      margin: '16px 24px 16px 24px',
      padding: '0px',
    },
    reasonText: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
    },
  }),
);

const DialogBoxTitle = styled(DialogTitle)`
  h2 {
    font-family: 'Nunito Sans' !important;
    font-weight: 700 !important;
    font-size: 21px !important;
    line-height: 29px !important;
    color: ${(props) => props.theme.errorRed} !important;
  }
`;

const RejectReason = styled(Typography)`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: flex-end !important;
  color: #4a4a4a !important;
  margin-bottom: 4px !important;
`;

const PendingInviteModal = (props) => {
  const { isModalOpen, handleModalClose, action, selectUser, handleAction, rejectReason, handleChange } = props;
  const classes = useStyles();
  const STAFlag = isSTAFeatureFlagEnabled();

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={isModalOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="co-sign-modal"
      >
        <DialogBoxTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <Grid container spacing={3}>
            <Grid item xs={11}>
              {action === APPROVE ? `Approve invite for “` : `Reject invite for “`}
              {''}
              {selectUser.user
                ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
                : selectUser?.address?.address}
              {`?`}
            </Grid>
            <Grid item xs={1} className={classes.dialogIconContainer}>
              <IconButton onClick={() => handleModalClose()} className={classes.dialogIconButton}>
                <CloseIcon color="#4A4A4A" width="15" height="15" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogBoxTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogDescription}>
            {action === APPROVE ? (
              STAFlag ? (
                `${
                  selectUser.user
                    ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
                    : selectUser?.address?.address
                } will receive an email invitation to join ${store.getState().organizationReducer?.name}.`
              ) : (
                `${
                  selectUser.user
                    ? `${selectUser?.user?.firstname} ${selectUser?.user?.lastname}`
                    : selectUser?.address?.address
                } will be allowed to join ${
                  store.getState().organizationReducer?.name
                } but will remain an Unlicensed user until they have registered their account. They will receive sign-up instructions via email or SMS.`
              )
            ) : (
              <>
                <RejectReason>Reason for rejection (Optional)</RejectReason>
                <TextField
                  multiline
                  fullWidth={true}
                  rows={5}
                  InputProps={{
                    className: classes.reasonText,
                  }}
                  value={rejectReason}
                  variant="outlined"
                  onChange={handleChange}
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <SecondaryButton data-testid="close-modal" type="submit" onClick={() => handleModalClose()}>
            Cancel
          </SecondaryButton>
          {action === APPROVE ? (
            <PrimaryButton type="button" data-testid="approve-invite" onClick={() => handleAction(action)}>
              Approve invite
            </PrimaryButton>
          ) : (
            <PrimaryButton type="button" data-testid="reject-invite" onClick={() => handleAction(action)}>
              Reject invite
            </PrimaryButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PendingInviteModal;
