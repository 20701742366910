import React, { useEffect, useState } from 'react';
import Routes from './router';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import AuthProvider from 'src/auth/AuthProvider';
import client from 'src/clients/apolloClient';
import { Provider } from 'react-redux';
import store from 'src/redux/store';
import StyledToastContainer from 'src/components/StyledToastContainer';
import { Auth0Provider } from '@auth0/auth0-react';
import PromptsManager from 'src/components/PromptsManager';

import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import ForcedLogoutTimerHandler from 'src/components/SessionTimeout/forcedLogoutTimerHandler';
import IdleTimerHandler from 'src/components/SessionTimeout/IdleTimerHandler';

import theme from 'src/assets/styles/theme';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import 'src/assets/styles/ModalStyles.scss';
import { useGetGeoLocation } from './utils/useGetGeoLocation';
import HypercareAuthRegionModalContext from './contexts/HypercareAuthRegionModalContext';
import AdminAccessModalContext from './contexts/AdminAccessModalContext';
import { ACCOUNT_QUERY_PARAM } from './constants/queryParams';
import { toast } from 'react-toastify';
import CustomToaster from './components/CustomToaster';
import { getDeviceName } from './utils/getDeviceName';
import { SESSION_EXPIRED_ERROR_MESSAGE } from './constants/strings';
import { checkSTAFeatureFlag, isSTAFeatureFlagEnabled } from './utils/sta/util';
import AuthProviderSTA from './auth/AuthProvider';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useFeatureFlagReload } from './hooks/useSTAFeatureFlagReload';

const onRedirectCallback = () => {
  window.history.replaceState({}, document.title, redirectUri);
  window.routerHistory.replace(`/ssoredirect`);
};

const redirectUri = `${window.location.origin}/ssoredirect`;

const App = () => {
  const sessionTimeoutFlag = IsFeatureFlagEnabled('sessionTimeout');
  const [changeRegionModalOpenStatus, setChangeRegionModalOpenStatus] = useState<boolean>(false);
  const [adminAccessModalContextOpenStatus, setAdminAccessModalContextOpenStatus] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const account = urlParams.get(ACCOUNT_QUERY_PARAM) || '';
  const LaunchDarklyClient = useLDClient();

  useFeatureFlagReload(LaunchDarklyClient);

  const { region } = useGetGeoLocation();
  const AuthContextProvider = isSTAFeatureFlagEnabled() ? AuthProviderSTA : AuthProvider;

  if (!region) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={redirectUri}
            onRedirecting={onRedirectCallback}
          >
            <AdminAccessModalContext.Provider
              value={{ adminAccessModalContextOpenStatus, setAdminAccessModalContextOpenStatus }}
            >
              <AuthContextProvider>
                <HypercareAuthRegionModalContext.Provider
                  value={{ changeRegionModalOpenStatus, setChangeRegionModalOpenStatus }}
                >
                  <div className="App" data-testid="app">
                    {sessionTimeoutFlag && (
                      <>
                        <ForcedLogoutTimerHandler />
                        <IdleTimerHandler />
                      </>
                    )}
                    <Routes />
                    {!account && <PromptsManager />}
                    <StyledToastContainer
                      position="top-center"
                      closeOnClick={true}
                      autoClose={4000}
                      hideProgressBar={true}
                    />
                  </div>
                </HypercareAuthRegionModalContext.Provider>
              </AuthContextProvider>
            </AdminAccessModalContext.Provider>
          </Auth0Provider>
        </Provider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
