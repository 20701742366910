import React from 'react';
import { QueryResult, useQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import UserProfile from 'src/pages/UserProfilePage/profile-layout/UserProfile';
import { GET_USER_PROFILE_ALT } from 'src/gql/query/GetUserProfileQuery';
import {
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_DIR_SYNC,
  FETCH_USER_PROFILE_DIR_SYNC_STA,
} from 'src/gql/v2/query/FetchUserProfileQuery';
import Loader from 'src/components/loaders/UserProfileLayoutLoader';
import UserProfileSubHeader from 'src/components/headers/UserProfileSubHeader';
import {
  ShorthandOrganizationType,
  FetchUserProfileResultFinal,
  GetAllLicensingUsersResult,
  User,
  UserLicensingStatus,
  MemberLicensingStatus,
} from 'src/types';
import getAdminTypeBaseOnScopes from 'src/utils/getAdminTypeBaseOnScopes';
import { GET_UNLICENSED_USERS } from 'src/gql/query/GetUsersQuery';
import { typedUseSelector } from 'src/redux/store';
import { useParams } from 'react-router-dom';
import allActions from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { LICENSED, PENDING, UNLICENSED } from 'src/constants/user';
import { checkOrganizationalUnit, getOrganizationObject } from 'src/utils/getOrganizationalUnitObject';
import UserProfileOPC from '../profile-layout/UserProfileOPC';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { ORG } from '../../../constants/strings';
import { ApolloQueryResult } from 'apollo-client';
import { isSelfUser } from 'src/utils/getLocalAuth';
import { isSTAFeatureFlagEnabled } from '../../../utils/sta/util';

/**
 * NOTE: since backend unable to add licenseStatus to user fragment, and profile need addressees info that are expensive to fetch initially in userList
 * in the case of user refresh the page at user profile page, FOUR queries will be fetched initially if haven't already
 *  - GET_USER_PROFILE: this only works iff user is licensed, so if has the result, the user will be licensed
 *  - GET_USER_PROFILE_ALT: this fetches the user that is unlicensed(aka blacklist in backend terms) OR pending; also this is public user, those scopes and expiry time are unable to be fetched
 *  - pendingUsersList,
 *  - blacklistedUsersList,
 *
 * NOTE: admin can only edit licensed users profile
 */

export const UserProfileLayoutLicensingContainer = () => {
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabels);
  const STAFlag = isSTAFeatureFlagEnabled();
  const { userId } = useParams<{ userId: string }>();

  const getUserQuery = () => {
    if (STAFlag) return FETCH_USER_PROFILE_DIR_SYNC_STA;
    if (ldapDirectorySyncFlag) {
      return FETCH_USER_PROFILE_DIR_SYNC;
    }

    return FETCH_USER_PROFILE;
  };

  const { data, loading, error, refetch } = useQuery<FetchUserProfileResultFinal>(getUserQuery(), {
    variables: {
      organizationalUnit: isSelfUser(userId) ? getOrganizationObject() : checkOrganizationalUnit(),
      userId,
      isProfileAddressLabelFlagEnabled: profileAddressLabelFlag,
      isSTAFlagEnabled: STAFlag,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loader />;

  if (error) {
    return (
      <div className="networkErrorHolder">
        <span>An Error Occurred, Please Check Your Internet Connection And Try Again.</span>
      </div>
    );
  }

  let MemberLicenseStatus: UserLicensingStatus;

  switch (data.adminQuery.organizationalUnit.member.memberStatus) {
    case 'active':
    case 'inactive':
      MemberLicenseStatus = 'licensed';
      break;
    case 'blocked':
      MemberLicenseStatus = 'unlicensed';
      break;
    case 'pending_admin_approval':
      MemberLicenseStatus = 'pending';
      break;
  }

  const userProfile = data.adminQuery.organizationalUnit.member;

  return <UserProfileLayout userLicenseStatus={MemberLicenseStatus} userProfile={userProfile} refetch={refetch} />;
};

const UserProfileLayout = ({
  userLicenseStatus,
  userProfile,
  refetch,
}: {
  userLicenseStatus: UserLicensingStatus;
  userProfile: User;
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<FetchUserProfileResultFinal>>;
}) => {
  const { userId } = useParams<{ userId: string }>();
  const isLicensedUser = userLicenseStatus === LICENSED;
  const dispatch = useDispatch();

  const sendProfileToStore = (user: User) => dispatch(allActions.headerAction.sendProfileToStore(user));

  const isAuthorized = typedUseSelector((state) => state.flagReducer.isAuthorized);
  const currentOrganization = typedUseSelector((state) => state.organizationReducer);
  const [adminType, setAdminType] = React.useState<ShorthandOrganizationType | ''>('');
  const hiddenNotes = IsFeatureFlagEnabled('hiddenNotes');
  const adminContactPreferences = IsFeatureFlagEnabled('orderPreferenceContacts');

  React.useEffect(() => {
    if (userProfile && userId && isLicensedUser) {
      const scopes = userProfile.scopes;
      const adminType = getAdminTypeBaseOnScopes(scopes);
      setAdminType(adminType);
    }
  }, [isLicensedUser, userId, userProfile, currentOrganization]);

  React.useEffect(() => {
    if (userProfile) {
      sendProfileToStore(userProfile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLicensedUser, userProfile]);

  if (!isAuthorized) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return (
    <>
      <UserProfileSubHeader adminType={adminType} profile={userProfile} />
      {!adminContactPreferences ? (
        <UserProfile
          user={userProfile}
          isAdmin={adminType === ORG}
          reFetchData={refetch}
          userLicenseStatus={userLicenseStatus}
          hiddenNotes={hiddenNotes}
        />
      ) : (
        <UserProfileOPC
          user={userProfile}
          isAdmin={adminType === ORG}
          reFetchData={refetch}
          userLicenseStatus={userLicenseStatus}
          hiddenNotes={hiddenNotes}
        />
      )}
    </>
  );
};
