import gql from 'graphql-tag';
import { ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type AdminAddProfileSectionMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        addSection: {
          id: string;
          sectionId: string;
          label: string;
          fields: Array<ProfileFieldFragmentType>;
        };
      };
    };
  };
};

export const ADMIN_ADD_PROFILE_SECTION_MUTATION = gql`
  mutation AddProfileSection(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $sectionDetails: CreateProfileSectionInput!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              ... on FullOrganizationMemberMutation {
                addSection(details: $sectionDetails) {
                  ... on ProfileSection {
                    id
                    sectionId
                    fields {
                      ... on Node {
                        id
                      }
                      ...ProfileFieldFragment
                      __typename
                      ... on ProfilePhoneField {
                        phoneNumber
                        placeholder
                      }
                      ... on ProfileTextField {
                        text
                        placeholder
                      }
                      ... on ProfileDropdownField {
                        options
                        selectedOption
                      }
                      ... on ProfileNumericField {
                        value
                      }
                      ... on ProfileLinkField {
                        url
                      }
                      ... on ProfileEmailField {
                        email
                      }
                      ... on ProfileDateField {
                        date
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment ProfileFieldFragment on ProfileField {
    fieldId
    label
    labelOptions
    visibility
    isSynced
    isEditable
    isRemovable
    isRequired
    validationRules {
      regex
    }
  }
`;
