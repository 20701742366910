import gql from 'graphql-tag';

export type DeleteProfileFieldMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        profileField: {
          deleteField: {
            id: string;
          };
        };
      };
    };
  };
};

export const ADMIN_DELETE_PROFILE_FIELD_MUTATION = gql`
  mutation DeleteProfileFields($organizationalUnit: OrganizationalUnitInput!, $userId: ID!, $uniqueFieldId: ID!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              ... on FullOrganizationMemberMutation {
                profileField(id: $uniqueFieldId) {
                  deleteField {
                    ... on Node {
                      id
                    }
                    ... on ProfilePhoneField {
                      ...ProfileFieldFragment
                      phoneNumber
                      placeholder
                    }
                    ... on ProfileTextField {
                      ...ProfileFieldFragment
                      text
                      placeholder
                    }
                    ... on ProfileDropdownField {
                      options
                      selectedOption
                    }
                    ... on ProfileNumericField {
                      value
                    }
                    ... on ProfileLinkField {
                      url
                    }
                    ... on ProfileEmailField {
                      email
                    }
                    ... on ProfileDateField {
                      date
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment ProfileFieldFragment on ProfileField {
    fieldId
    label
    labelOptions
    visibility
    isSynced
    isEditable
    isRemovable
    isRequired
    validationRules {
      regex
    }
  }
`;
