import gql from 'graphql-tag';
import { GQLErrorType } from '../types/result';

export type ArchiveMemberMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member:
        | {
            __typename: 'FullOrganizationMemberMutation';
            archive:
              | {
                  __typename: 'FullOrganizationMember';
                  id: string;
                  firstName: string;
                  lastName: string;
                  role: string;
                }
              | GQLErrorType<'CannotArchiveActiveMember'>
              | GQLErrorType<'CannotArchiveInActiveMember'>;
          }
        | GQLErrorType<'UserNotFoundError'>;
    };
  };
};

export const ARCHIVE_MEMBER = gql`
  mutation ArchiveMember($userId: ID!, $organizationalUnit: OrganizationalUnitInput!) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              __typename
              ... on FullOrganizationMemberMutation {
                archive {
                  __typename
                  ...FullOrganizationMemberFragment
                  ... on Error {
                    message
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment FullOrganizationMemberFragment on FullOrganizationMember {
    id
    firstName
    lastName
    role
  }
`;
