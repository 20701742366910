import React from 'react';
import styled from 'styled-components';
import { HCHeadingThree, HCLabelOne } from 'src/components/shared/HypercareComponents';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatMessage } from './DynamicMaintenancePageUtils';

const BannerContainer = styled.div<{ $backgroundColor?: string }>`
  background-color: ${(props) => props.$backgroundColor || 'red'};
  padding: 20px;
  text-align: center;
`;

const LoginDisabledBanner = () => {
  const { dynamicMaintenancePage } = useFlags();

  return (
    <BannerContainer $backgroundColor={dynamicMaintenancePage?.login?.styles?.backgroundColor}>
      <HCHeadingThree
        color={dynamicMaintenancePage?.login?.styles?.textColor}
        fontSize={dynamicMaintenancePage?.login?.styles?.titleFontSize}
      >
        {dynamicMaintenancePage?.login?.messages?.[0]?.title}
      </HCHeadingThree>
      <HCLabelOne
        color={dynamicMaintenancePage?.login?.styles?.textColor}
        fontSize={dynamicMaintenancePage?.login?.styles?.descriptionFontSize}
      >
        {formatMessage(
          dynamicMaintenancePage?.login?.messages?.[0]?.body,
          dynamicMaintenancePage?.login?.messages?.[0]?.parameters,
        )}
      </HCLabelOne>
    </BannerContainer>
  );
};

export default LoginDisabledBanner;
