import * as React from 'react';
import { useEffect, useState } from 'react';
import { MenuItem, withStyles } from '@material-ui/core';
import { HCBodyTwo, HCLabelOne, HCLabelThree, HCLabelTwo } from 'src/components/shared/HypercareComponents';
import styled from 'styled-components';
import ConfigureNumberViewModel from '../../../ConfigureNumberViewModel';
import { audioObject, AudioModalType, DefaultAudioMap } from 'src/types';
import DashedLine from 'src/assets/svgs/DashedLine';
import AddIcon from 'src/assets/svgs/AddIcon';
import CloseIcon from 'src/assets/svgs/CloseIcon';
import ModalAddAudioView from '../../../ModalAddAudioView';
import { typedUseSelector } from 'src/redux/store';
import theme from 'src/assets/styles/theme';
import AudioSelectionTextField from '../../AudioSelectionTextField';
import { useDispatch } from 'react-redux';
import { setEditedPager } from 'src/redux/actions/virtualPagersActions';
import {
  ADD_NEW_CALLBACK,
  ADD_NEW_VOICEMAIL,
  ASK_TO_LEAVE_CALLBACK,
  ASK_TO_LEAVE_VOICEMAIL,
  OPTIONAL,
  REMOVE,
} from 'src/constants/virtualPagerStrings';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const CircleIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
`;

const OptionalCircleIcon = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  gap: 8px;
  margin-bottom: 16px;
  width: max-content;
`;

const StepHeadingContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StepContentContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StepNumberAndLabelContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
const StyledMenuItem = withStyles({
  root: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
})(MenuItem);

const AddNewMessageLink = styled.a<{ isDisabled: boolean }>`
  text-align: right;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
`;

const VoicemailLink = styled.a`
  cursor: pointer;
  text-decoration-line: underline;
`;

const CloseBtn = styled.div<{ isDisabled: boolean }>`
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  position: relative;
  font-size: 14px;
  opacity: 0.8;
  display: flex;
  gap: 4px;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`;

type Props = {
  isPagerDisabled: boolean;
  defaultAudioMap: DefaultAudioMap;
  setDefaultAudioMap: React.Dispatch<React.SetStateAction<DefaultAudioMap>>;
  selectedCallbackAudio: audioObject | null;
  setSelectedCallbackAudio: React.Dispatch<React.SetStateAction<audioObject | null>>;
  selectedVoicemailAudio: audioObject | null;
  setSelectedVoicemailAudio: React.Dispatch<React.SetStateAction<audioObject | null>>;
};

const AudioAccordionDetails: React.FC<Props> = ({
  isPagerDisabled,
  defaultAudioMap,
  setDefaultAudioMap,
  selectedCallbackAudio,
  setSelectedCallbackAudio,
  selectedVoicemailAudio,
  setSelectedVoicemailAudio,
}) => {
  const dispatch = useDispatch();

  const { fetchOrgCallbacks, fetchOrgVoicemails, fetchSingleAudio } = ConfigureNumberViewModel();
  const { editedPager, currentPager } = typedUseSelector((state) => state.virtualPagerReducer);
  const OrgId = typedUseSelector((state) => state.organizationReducer.organization_id);

  const [voicemailStepVisible, setVoicemailStepVisible] = useState<boolean>(Boolean(currentPager.voicemail));
  const [modalType, setModalType] = useState<AudioModalType | null>(null);

  // These useStates are used to set the Id and Name from the menu items
  const [orgCallbackAudios, setOrgCallbackAudios] = useState<audioObject[] | null>(null);
  const [orgVoicemailAudios, setOrgVoicemailAudios] = useState<audioObject[] | null>(null);

  const openCallbackAudioModal = () => {
    setModalType('callback');
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaAddNewCallbackPressed,
    });
  };

  const openVoicemailAudioModal = () => {
    setModalType('voicemail');
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaAddNewVoicemailPressed,
    });
  };

  const closeAudioModal = () => {
    setModalType(null);
  };

  const openVoicemailSelection = () => {
    setSelectedVoicemailAudio(orgVoicemailAudios[0]);
    dispatch(setEditedPager({ ...editedPager, voicemail: defaultAudioMap.voicemail }));
    setVoicemailStepVisible(true);
  };

  const removeVoiceMail = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pvpaRemoveVoicemailPressed,
      params: {
        virtual_pager_number: currentPager.pagerNumber,
      },
    });
    setVoicemailStepVisible(false);
    setSelectedVoicemailAudio(null);
    dispatch(setEditedPager({ ...editedPager, voicemail: null }));
  };

  const changeCallbackAudio = (audio: audioObject) => {
    setSelectedCallbackAudio(audio);
    dispatch(setEditedPager({ ...editedPager, callback: audio.id }));
  };

  const changeVoicemailAudio = (audio: audioObject) => {
    setSelectedVoicemailAudio(audio);
    dispatch(setEditedPager({ ...editedPager, voicemail: audio.id }));
  };

  const fetchOrgAndAssignedAudios = async () => {
    const [callbackAudioRes, voicemailAudioRes] = await Promise.all([
      fetchOrgCallbacks(OrgId, 10),
      fetchOrgVoicemails(OrgId, 10),
    ]);
    const callbackSelections = [callbackAudioRes.defaultAudio, ...callbackAudioRes.audioSteps];
    const voicemailSelections = [voicemailAudioRes.defaultAudio, ...voicemailAudioRes.audioSteps];

    setDefaultAudioMap({ callback: callbackAudioRes.defaultAudio.id, voicemail: voicemailAudioRes.defaultAudio.id });
    setOrgCallbackAudios(callbackSelections);
    setOrgVoicemailAudios(voicemailSelections);

    const fetchCurrentCallbackAudio = async () => {
      if (editedPager.callback !== null) {
        const currentCallbackAudio = await fetchSingleAudio(editedPager.callback);
        setSelectedCallbackAudio(currentCallbackAudio);
        if (!callbackSelections.find((audio) => audio.id === currentCallbackAudio.id)) {
          setOrgCallbackAudios((prev) => [...prev, currentCallbackAudio]);
        }
      } else {
        setSelectedCallbackAudio(callbackAudioRes.defaultAudio);
        dispatch(setEditedPager({ ...editedPager, callback: callbackAudioRes.defaultAudio.id }));
      }
    };

    const fetchCurrentVoicemailAudio = async () => {
      if (editedPager.voicemail !== null) {
        const currentVoicemailAudio = await fetchSingleAudio(editedPager.voicemail);
        setSelectedVoicemailAudio(currentVoicemailAudio);
        if (!voicemailSelections.find((audio) => audio.id === currentVoicemailAudio.id)) {
          setOrgVoicemailAudios((prev) => [...prev, currentVoicemailAudio]);
        }
      }
    };

    await Promise.all([fetchCurrentCallbackAudio(), fetchCurrentVoicemailAudio()]);
  };

  const onCreateAudioSuccess = async (modalType: AudioModalType, audioStep: audioObject) => {
    await fetchOrgAndAssignedAudios();
    if (modalType === 'callback') {
      dispatch(setEditedPager({ ...editedPager, callback: audioStep.id }));
      setSelectedCallbackAudio(audioStep);
    } else {
      dispatch(setEditedPager({ ...editedPager, voicemail: audioStep.id }));
      setSelectedVoicemailAudio(audioStep);
    }
  };

  useEffect(() => {
    fetchOrgAndAssignedAudios();
  }, []);

  return (
    <>
      <StepsContainer>
        <StepHeadingContainer>
          <StepNumberAndLabelContainer>
            <CircleIcon>
              <HCLabelTwo color="white">1</HCLabelTwo>
            </CircleIcon>
            <HCBodyTwo afterContent={' *'} fontWeight={600}>
              {ASK_TO_LEAVE_CALLBACK}
            </HCBodyTwo>
          </StepNumberAndLabelContainer>
        </StepHeadingContainer>
        <StepContentContainer>
          <div style={{ margin: '0px 11px' }}>
            <DashedLine />
          </div>
          <div>
            <AudioSelectionTextField
              src={selectedCallbackAudio ? selectedCallbackAudio.audio : ''}
              value={selectedCallbackAudio ? selectedCallbackAudio.name : ''}
              disabled={isPagerDisabled}
            >
              <div onClick={openCallbackAudioModal}>
                <StyledMenuItem key="link" value="link">
                  <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                    <AddIcon fill={theme.mainTealColor} />
                    <HCLabelOne color={theme.mainTealColor}>{ADD_NEW_CALLBACK}</HCLabelOne>
                  </div>
                </StyledMenuItem>
              </div>
              {orgCallbackAudios &&
                orgCallbackAudios.map((audio) => (
                  <StyledMenuItem key={audio.id} value={audio.name} onClick={() => changeCallbackAudio(audio)}>
                    <HCLabelOne color={isPagerDisabled ? theme.warmGrey : 'inheret'}>{audio.name}</HCLabelOne>
                  </StyledMenuItem>
                ))}
            </AudioSelectionTextField>
            <AddNewMessageLink isDisabled={isPagerDisabled} onClick={!isPagerDisabled ? openCallbackAudioModal : null}>
              <HCLabelThree
                color={isPagerDisabled ? theme.warmGrey : theme.mainTealColor}
                fontWeight={700}
                style={{ marginTop: '8px' }}
              >
                {ADD_NEW_CALLBACK}
              </HCLabelThree>
            </AddNewMessageLink>
          </div>
        </StepContentContainer>

        {!voicemailStepVisible ? (
          <StepHeadingContainer>
            <StepNumberAndLabelContainer>
              <OptionalCircleIcon>
                <AddIcon fill={theme.black} width={9.33} height={9.33} />
              </OptionalCircleIcon>
              <VoicemailLink onClick={openVoicemailSelection}>
                <HCLabelTwo>
                  {OPTIONAL}: {ASK_TO_LEAVE_VOICEMAIL}
                </HCLabelTwo>
              </VoicemailLink>
            </StepNumberAndLabelContainer>
          </StepHeadingContainer>
        ) : (
          <StepHeadingContainer>
            <StepNumberAndLabelContainer>
              <CircleIcon>
                <HCLabelTwo color="white">2</HCLabelTwo>
              </CircleIcon>
              <HCBodyTwo fontWeight={600}>{ASK_TO_LEAVE_VOICEMAIL}</HCBodyTwo>
            </StepNumberAndLabelContainer>
            <CloseBtn isDisabled={isPagerDisabled} onClick={!isPagerDisabled ? removeVoiceMail : null}>
              <CloseIcon
                width="9"
                height="9"
                fill={isPagerDisabled ? theme.warmGrey : theme.darkenFontColor}
                strokeWidth="2"
              />
              <HCLabelTwo fontWeight={600} color={isPagerDisabled ? theme.warmGrey : theme.darkenFontColor}>
                {REMOVE}
              </HCLabelTwo>
            </CloseBtn>
          </StepHeadingContainer>
        )}
        {voicemailStepVisible ? (
          <StepContentContainer>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
              <AudioSelectionTextField
                src={selectedVoicemailAudio ? selectedVoicemailAudio.audio : ''}
                value={selectedVoicemailAudio ? selectedVoicemailAudio.name : ''}
                disabled={isPagerDisabled}
              >
                <div onClick={openVoicemailAudioModal}>
                  <StyledMenuItem key="link" value="link">
                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                      <AddIcon fill={theme.mainTealColor} />
                      <HCLabelOne color={theme.mainTealColor}>{ADD_NEW_VOICEMAIL}</HCLabelOne>
                    </div>
                  </StyledMenuItem>
                </div>

                {orgVoicemailAudios &&
                  orgVoicemailAudios.map((audio) => (
                    <StyledMenuItem key={audio.id} value={audio.name} onClick={() => changeVoicemailAudio(audio)}>
                      <HCLabelOne color={isPagerDisabled ? theme.warmGrey : 'inheret'}>{audio.name}</HCLabelOne>
                    </StyledMenuItem>
                  ))}
              </AudioSelectionTextField>
              <AddNewMessageLink
                isDisabled={isPagerDisabled}
                onClick={!isPagerDisabled ? openVoicemailAudioModal : null}
              >
                <HCLabelThree
                  color={isPagerDisabled ? theme.warmGrey : theme.mainTealColor}
                  fontWeight={700}
                  style={{ marginTop: '8px' }}
                >
                  {ADD_NEW_VOICEMAIL}
                </HCLabelThree>
              </AddNewMessageLink>
            </div>
          </StepContentContainer>
        ) : null}
      </StepsContainer>

      <ModalAddAudioView
        type={modalType}
        isOpen={modalType !== null}
        closeModal={closeAudioModal}
        orgCallbackAudioNames={orgCallbackAudios && orgCallbackAudios.map((audio) => audio.name)}
        orgVoicemailAudioNames={orgVoicemailAudios && orgVoicemailAudios.map((audio) => audio.name)}
        onCreateAudioSuccess={(audio: audioObject) => onCreateAudioSuccess(modalType, audio)}
      />
    </>
  );
};

export default AudioAccordionDetails;
