import React from 'react';
import { UserRepository } from '../../data/repository/UserRepository';
import { FetchResult } from 'apollo-link';
import { AddMemberToScopeSTAMutation } from 'src/gql/mutation/AddMemberToScopeSTAMutation';

export const InviteUserViewModel = () => {
  const { addMemberToScope } = UserRepository();

  const processResponse = (response: FetchResult<AddMemberToScopeSTAMutation>, success: string[], fail: string[]) => {
    const addMember = response?.data?.adminMutation?.organizationalUnit?.addMember;

    if (!addMember) {
      fail.push('Unknown error');
      return;
    }

    switch (addMember.__typename) {
      case 'UserAlreadyExistsForScope':
        fail.push('User already exists for given scope');
        break;

      case 'FullOrganizationMember':
        const { firstName, lastName } = addMember;
        success.push(`${firstName} ${lastName}`);
        break;

      default:
        fail.push('Unhandled response type');
        break;
    }
  };

  const handleAddAllMembersToScope = async ({ userIds }: { userIds: string[] }) => {
    const success: string[] = [];
    const fail: string[] = [];

    try {
      const results = await Promise.all(
        userIds.map((userId) => addMemberToScope({ userId }).catch((error) => ({ error }))),
      );

      results.forEach((response) => {
        if ('error' in response) {
          console.error('Error adding user:', response.error);
          fail.push(response.error.message || 'Unknown error');
        } else {
          processResponse(response.response, success, fail);
        }
      });

      return { success, fail };
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      fail.push('An unexpected error occurred');
      return { success, fail };
    }
  };

  return { handleAddAllMembersToScope };
};
