import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { UserMenuOptionStatus } from 'src/types';
import InviteUserModal from 'src/components/modals/InviteUserModal';
import { User, GetAllLicensingUsersResult, GetPendingUsersResult } from 'src/types';
import { QueryResult } from 'react-apollo';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { StyledHypercareChip } from 'src/components/shared/StyledHypercareChip';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { DIR_SYNC_TOP_PANEL_PILL_SUPPORT_LINK } from 'src/constants/strings';
import {
  DIR_SYNC_MANAGEMENT_DISABLED,
  DIR_SYNC_MANAGEMENT_DISABLED_TOOLTIP_TEXT,
  DIR_SYNC_MANAGEMENT_ENABLED,
  DIR_SYNC_MANAGEMENT_ENABLED_TOOLTIP_TEXT,
} from 'src/constants/dirSyncConstants';
import { LEARN_MORE } from 'src/constants/virtualPagerStrings';
import { StyledLink, StyledToolTipContainer } from 'src/components/shared/SharedStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import { HCBodyTwo, HCTextContext } from 'src/components/shared/HypercareComponents';
import theme from 'src/assets/styles/theme';
import { InviteUserResponseModal } from 'src/components/modals/InviteUserResponseModal';
import { FetchPaginatedUsersQueryResponse } from '../../../gql/v2/query/FetchPaginatedUsersQuery';
import { FetchPaginatedUsersQueryResponseSTA } from '../../../gql/v2/query/sta/FetchPaginatedUsersQuerySTA';
import { isSTAFeatureFlagEnabled } from '../../../utils/sta/util';

const TopPanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  user-select: none;
`;

const Title = styled.div`
  color: ${(props) => props.theme.mainFontColor};
  font-family: Nunito;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  padding-right: 10px;
`;

interface Props {
  allUsers?: User[];
  setMenuOptions: React.Dispatch<React.SetStateAction<UserMenuOptionStatus>>;
  userRecords: QueryResult<
    FetchPaginatedUsersQueryResponse | GetAllLicensingUsersResult | FetchPaginatedUsersQueryResponseSTA
  >;
  pendingRecords?: QueryResult<
    GetPendingUsersResult | FetchPaginatedUsersQueryResponse | FetchPaginatedUsersQueryResponseSTA
  >;
  orgDirSyncStatus: Boolean;
  onAddUserSuccess: () => void;
}

const SidePanel: React.FC<Props> = ({
  allUsers,
  setMenuOptions,
  onAddUserSuccess,
  userRecords,
  pendingRecords,
  orgDirSyncStatus,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [inviteResponse, setInviteResponse] = React.useState([]);
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const [showDirSyncToolTip, setShowDirSyncToolTip] = useState<HTMLElement | null>(null);
  const [addressesInput, setAddressesInput] = useState<any[]>([]);
  const STAFlag = isSTAFeatureFlagEnabled();

  const handleOpenModal = () => {
    setShowModal(true);
    setShowResponseModal(false);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.addUserButtonPressed,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.cancelAddUserModalButtonPressed,
    });
  };

  const handleOnCloseResponseModal = () => {
    setShowResponseModal(false);
  };

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>) => {
    setShowDirSyncToolTip(e.currentTarget);
  };

  const handlePopoverClose = (e: React.MouseEvent) => {
    setShowDirSyncToolTip(null);
  };
  const userFullNameLists = allUsers?.map((user) => user?.username);
  return (
    <React.Fragment>
      <TopPanelWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title>Users</Title>
          {ldapDirectorySyncFlag && (
            <div onMouseEnter={handlePopoverOpen}>
              <StyledHypercareChip
                type={orgDirSyncStatus ? 'neutral' : 'warning'}
                valueClassName={'OrganizationHeader'}
                text={orgDirSyncStatus ? DIR_SYNC_MANAGEMENT_ENABLED : DIR_SYNC_MANAGEMENT_DISABLED}
              />
              {showDirSyncToolTip && (
                <Popover
                  open={Boolean(showDirSyncToolTip)}
                  anchorEl={showDirSyncToolTip}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  className="popper__container notes__popover"
                  disablePortal
                >
                  <ClickAwayListener onClickAway={() => setShowDirSyncToolTip(null)}>
                    <StyledToolTipContainer onMouseLeave={handlePopoverClose}>
                      <HCTextContext>
                        {orgDirSyncStatus ? DIR_SYNC_MANAGEMENT_ENABLED : DIR_SYNC_MANAGEMENT_DISABLED}
                      </HCTextContext>
                      <HCBodyTwo>
                        {orgDirSyncStatus
                          ? DIR_SYNC_MANAGEMENT_ENABLED_TOOLTIP_TEXT
                          : DIR_SYNC_MANAGEMENT_DISABLED_TOOLTIP_TEXT}{' '}
                        <StyledLink href={DIR_SYNC_TOP_PANEL_PILL_SUPPORT_LINK}>
                          <span style={{ color: theme.blueBorder }}>{LEARN_MORE}</span>
                        </StyledLink>
                      </HCBodyTwo>
                    </StyledToolTipContainer>
                  </ClickAwayListener>
                </Popover>
              )}
            </div>
          )}
        </div>
        <Button disableTouchRipple variant="contained" color="secondary" onClick={handleOpenModal}>
          Add User
        </Button>
      </TopPanelWrapper>
      {showModal && (
        <InviteUserModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          userFullNameLists={userFullNameLists}
          setMenuOptions={setMenuOptions}
          userRecords={userRecords}
          pendingRecords={pendingRecords}
          onAddUserSuccess={onAddUserSuccess}
          setInviteResponse={setInviteResponse}
          setAddressesInput={setAddressesInput}
          setShowResponseModal={setShowResponseModal}
        />
      )}
      {STAFlag && showResponseModal && (
        <InviteUserResponseModal
          isOpen={showResponseModal}
          handleOnCloseResponseModal={handleOnCloseResponseModal}
          handleOnClick={handleOpenModal}
          inviteResponse={inviteResponse}
          addressesInput={addressesInput}
        />
      )}
    </React.Fragment>
  );
};

export default SidePanel;
