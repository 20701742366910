import React from 'react';
import styled from 'styled-components';
import theme from 'src/assets/styles/theme';
import { Button } from '@material-ui/core';
import { StyledTooltipWhite } from '../StyledTooltip';

const ProfileDangerZoneOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
  background: #fafafa;
  border: 1px solid ${theme.borderBottomLight};
  border-radius: 4px;
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;
`;

const ProfileDangerZoneOptionsHeading = styled.div`
  color: #222;
  font-family: Nunito Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`;

const DangerZoneInfoSubHeading = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.mainFontColor};
  text-align: left;
`;

const DangerZoneInfoDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface Props {
  heading: String;
  subHeading: String;
  buttonText: String;
  onButtonClick: () => void;
  disabled: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
}

function DangerZoneActionButton(props: { onClick: () => void; disabled: boolean; buttonText: String }) {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={props.onClick}
      disabled={props.disabled}
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      {props.buttonText}
    </Button>
  );
}

const ProfileDangerZoneOptions: React.FC<Props> = ({
  heading,
  subHeading,
  buttonText,
  onButtonClick,
  disabled,
  showTooltip,
  tooltipText,
}) => {
  return (
    <>
      {
        <ProfileDangerZoneOptionsContainer>
          <div>
            <ProfileDangerZoneOptionsHeading>{heading}</ProfileDangerZoneOptionsHeading>
            <DangerZoneInfoSubHeading>{subHeading}</DangerZoneInfoSubHeading>
          </div>
          <DangerZoneInfoDescription>
            <ButtonWrapper>
              {showTooltip ? (
                <StyledTooltipWhite arrow title={tooltipText} position={'left-end'}>
                  <div>
                    <DangerZoneActionButton onClick={onButtonClick} disabled={disabled} buttonText={buttonText} />
                  </div>
                </StyledTooltipWhite>
              ) : (
                <DangerZoneActionButton onClick={onButtonClick} disabled={disabled} buttonText={buttonText} />
              )}
            </ButtonWrapper>
          </DangerZoneInfoDescription>
        </ProfileDangerZoneOptionsContainer>
      }
    </>
  );
};

export default ProfileDangerZoneOptions;
