import React from 'react';
import { connect } from 'react-redux';
import OrganizationDropdown from 'src/components/popup-dropdowns/OrganizationDropdown';
import ChevronDown from 'src/assets/svgs/ChevronDown';
import trySwitchOrganization from 'src/utils/trySwitchOrganization';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { UserOrganizationSwitcherPayload } from 'src/types';
import { RootState, typedUseSelector } from 'src/redux/store';
import { organizationDropDownAction } from 'src/redux/actions/flagAction';
import { useDispatch } from 'react-redux';
import ChevronUp from 'src/assets/svgs/ChevronUp';
import allActions from '../../../redux/actions';
import { UserViewModel } from '../../../pages/HomePage/viewModels/UserViewModel';

interface OrganizationLogoNameProps {
  currentOrganization: UserOrganizationSwitcherPayload;
  isOpen: boolean;
}

const OrganizationLogoName = ({ currentOrganization, isOpen }: OrganizationLogoNameProps) => {
  return (
    <div className="globalHeader__organization__name">
      <span>{currentOrganization.name ? currentOrganization.name : ''}</span>
      <div className="chevronHolder">{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
    </div>
  );
};

interface OrganizationSwitcherProps {
  currentOrganization: UserOrganizationSwitcherPayload;
  isOpen: boolean;
}

const OrganizationSwitcher = ({ currentOrganization, isOpen }: OrganizationSwitcherProps) => {
  const node = React.useRef(null);
  const dispatch = useDispatch();

  const { getOrganizationScopeList } = UserViewModel();

  const userOrganization = typedUseSelector((store) => store.userOrganizationReducer.organizations);
  const setOpen = () => {
    dispatch(organizationDropDownAction(isOpen ? false : true));
  };

  React.useEffect(() => {
    const handleClick = (e) => {
      if (node.current && node.current.contains(e.target)) return;
      dispatch(organizationDropDownAction(false));
    };
    if (isOpen) document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [dispatch, isOpen]);

  const getUserOrganizations = async () => {
    if (!userOrganization) {
      const result = await getOrganizationScopeList();
      if (result) {
        dispatch(allActions.userOrganizationAction.setUserOrganizations(result.me.organizations));
      }
    }
  };

  React.useEffect(() => {
    if (isOpen) AnalyticsManager.recordPageVisited(PAGE_VIEWS.switchOrganizationView);
  }, [isOpen]);

  if (currentOrganization.organization_id) {
    return (
      <div
        ref={node}
        className="organizationDropdownToggler"
        onClick={() => {
          setOpen();
          getUserOrganizations();
        }}
      >
        <OrganizationLogoName currentOrganization={currentOrganization} isOpen={isOpen} />
        <OrganizationDropdown
          options={userOrganization}
          onSelect={trySwitchOrganization}
          isOpen={isOpen}
          isReadOnly={true}
        />
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state: RootState) => {
  return {
    currentOrganization: state.organizationReducer,
    isOpen: state.flagReducer.isOpen,
  };
};

export default connect(mapStateToProps)(OrganizationSwitcher);
