export const V2_OPERATIONS_OBJECT = {
  FetchUser: 'FetchUser',
  LogoutUserDevice: 'LogoutUserDevice',
  CreateProfileNote: 'CreateProfileNote',
  FetchProfileNotesByMember: 'FetchProfileNotesByMember',
  UpdateProfileNote: 'UpdateProfileNote',
  DeleteProfileNote: 'DeleteProfileNote',
  FetchSelfProfileNotes: 'FetchSelfProfileNotes',
  DeleteSelfProfileNote: 'DeleteSelfProfileNote',
  OrganizationalUnitQuery: 'OrganizationalUnitQuery',
  UpdateUserAddressPreference: 'UpdateUserAddressPreference',
  FetchUserProfile: 'FetchUserProfile',
  CreateRoleNote: 'CreateRoleNote',
  DeleteRoleNote: 'DeleteRoleNote',
  UpdateRoleNote: 'UpdateRoleNote',
  roleNotes: 'roleNotes',
  updateProfile: 'updateProfile',
  FetchMembers: 'FetchMembers',
  FetchProfileFieldsSchema: 'FetchProfileFieldsSchema',
  AddProfileFields: 'AddProfileFields',
  UpdateProfileFields: 'UpdateProfileFields',
  DeleteProfileFields: 'DeleteProfileFields',
  FetchProfileFieldsForUser: 'FetchProfileFieldsForUser',
  FetchDirectorySyncStatus: 'FetchDirectorySyncStatus',
  FetchUserProfileWithDirSyncFields: 'FetchUserProfileWithDirSyncFields',
  GetAddressLabels: 'GetAddressLabels',
  EditUserAddressLabel: 'EditUserAddressLabel',
  EditUserAddressAccess: 'EditUserAddressAccess',
  FetchProfileNotes: 'FetchProfileNotes',
  FetchPaginatedInvites: 'FetchPaginatedInvites',
  FetchPaginatedRemovedUsers: 'FetchPaginatedRemovedUsers',
  FetchPaginatedUsers: 'FetchPaginatedUsers,',
  SearchRemovedUsers: 'SearchRemovedUsers',
  SearchUsers: 'SearchUsers',
  SearchPendingInvites: 'SearchPendingInvites',
  AddProfileSection: 'AddProfileSection',
  CreateShellAccount: 'CreateShellAccount',
  CreateLicensedAccount: 'CreateLicensedAccount',
  FetchMembersByUserIds: 'FetchMembersByUserIds',
  FetchDevicesForUser: 'FetchDevicesForUser',
  InviteUsers: 'InviteUsers',
  fetchMembersByIds: 'fetchMembersByIds',
  RemoveSessionById: 'RemoveSessionById',
  UpdateAddressPreferenceSTA: 'UpdateAddressPreferenceSTA',
  UpdateAddressLabelSTA: 'UpdateAddressLabelSTA',
  RemoveAddressSTA: 'RemoveAddressSTA',
  UpdateAddressAccessSTA: 'UpdateAddressAccessSTA',
  AddAddresses: 'AddAddresses',
  SearchPendingInvitesSTA: 'SearchPendingInvitesSTA',
  RemoveDeviceForUser: 'RemoveDeviceForUser',
  ApproveInvite: 'ApproveInvite',
  RejectInvite: 'RejectInvite',
  addMemberToScopeSTA: 'addMemberToScopeSTA',
  ArchiveMember: 'ArchiveMember',
};
