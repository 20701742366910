import React from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { ADMIN_UPDATE_MEMBER_SYNC_STATUS } from 'src/gql/v2/mutation/AdminUpdateMemberSyncStatusMutation';
import { ADMIN_GET_MEMBER_SYNC_STATUS } from 'src/gql/v2/query/FetchDirectorySyncStatus';
import { checkOrganizationalUnit, getOrganizationObject } from 'src/utils/getOrganizationalUnitObject';
import {
  FetchDirectorySyncStatusResponse,
  FetchMembersResult,
  FetchUserProfileResultFinal,
  UpdateDirectorySyncStatusResult,
} from 'src/types';
import { FETCH_USER_PROFILE, FETCH_USER_PROFILE_DIR_SYNC } from 'src/gql/v2/query/FetchUserProfileQuery';
import { FETCH_DIR_SYNC_MEMBERS, FETCH_MEMBERS } from 'src/gql/v2/query/FetchMembersQuery';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { GET_SELF_PROFILE, GET_SELF_SCOPES } from 'src/gql/query/GetUserProfileQuery';
import { NETWORK_ERROR } from 'src/constants/networkError';
import { isSelfUser } from 'src/utils/getLocalAuth';
import { isSTAFeatureFlagEnabled } from '../../utils/sta/util';

type FetchOrgDirSyncStatusError = null | 'networkError' | 'anotherError(complete me)';
type FetchProfilePageError = null | 'networkError' | 'anotherError(complete me)';
type FetchMembersError = null | 'networkError' | 'anotherError(complete me)';
type FetchSelfProfileError = null | 'networkError' | 'anotherError(complete me)';

export const DirSyncRepository = () => {
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabels);
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const STAFlag = isSTAFeatureFlagEnabled();

  const useFetchSelProfile = () => {
    const result = useQuery(GET_SELF_SCOPES, {
      fetchPolicy: 'no-cache',
    });
    let error: FetchSelfProfileError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }
    return {
      ...result,
      error,
    };
  };

  const useFetchMembers = () => {
    const result = useQuery<FetchMembersResult>(ldapDirectorySyncFlag ? FETCH_DIR_SYNC_MEMBERS : FETCH_MEMBERS, {
      variables: {
        organizationalUnit: checkOrganizationalUnit(),
      },
    });

    let error: FetchMembersError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchUserProfilePage = (userId: string) => {
    const result = useQuery<FetchUserProfileResultFinal>(
      ldapDirectorySyncFlag ? FETCH_USER_PROFILE_DIR_SYNC : FETCH_USER_PROFILE,
      {
        variables: {
          organizationalUnit: isSelfUser(userId) ? getOrganizationObject() : checkOrganizationalUnit(),
          userId,
          isProfileAddressLabelFlagEnabled: profileAddressLabelFlag,
          isSTAFlagEnabled: STAFlag,
        },
        fetchPolicy: 'no-cache',
      },
    );

    let error: FetchProfilePageError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchOrgDirSyncStatus = () => {
    const result = useQuery<FetchDirectorySyncStatusResponse>(ADMIN_GET_MEMBER_SYNC_STATUS, {
      variables: {
        organizationalUnit: checkOrganizationalUnit(),
        isDirSyncFeatureFlagEnabled: ldapDirectorySyncFlag,
      },
    });

    let error: FetchOrgDirSyncStatusError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useUpdateMemberDirSyncStatus = () => {
    const result = useMutation<
      UpdateDirectorySyncStatusResult,
      { userId: string; syncStatus: boolean; organizationalUnit: { id: number; type: string } }
    >(ADMIN_UPDATE_MEMBER_SYNC_STATUS, {
      fetchPolicy: 'no-cache',
    });
    let error = null;

    if (result[1]?.data?.adminMutation?.organizationalUnit?.member?.updateSyncStatus?.message) {
      error = result[1]?.data?.adminMutation?.organizationalUnit?.member?.updateSyncStatus?.message;
    }

    if (result[1]?.error?.message) {
      error = NETWORK_ERROR;
    }

    return {
      UpdateMemberDirSyncStatus: result[0],
      ...result[1],
      error,
    };
  };

  return {
    useFetchSelProfile,
    useFetchMembers,
    useFetchUserProfilePage,
    useFetchOrgDirSyncStatus,
    useUpdateMemberDirSyncStatus,
  };
};

export default DirSyncRepository;
