import gql from 'graphql-tag';
import { FullOrganizationMemberFragment } from 'src/gql/fragment/FullOrganizationMemberFragment';
import { PaginatedUsersType } from 'src/types/PaginatedTypes';

export type SearchUsersQueryResponse = {
  adminQuery: {
    organizationalUnit: {
      searchQuery: {
        searchUsers: {
          continuationId: string;
          totalResultsCount: number;
          users: PaginatedUsersType[];
        };
      };
    };
  };
};

export const SEARCH_USERS_QUERY_STA = gql`
  query SearchUsers(
    $organizationalUnit: OrganizationalUnitInput!
    $text: String!
    $direction: PaginationDirection
    $continuationId: ID
    $isDirSyncFeatureFlagEnabled: Boolean!
    $limit: Int
  ) {
    adminQuery {
      __typename
      ... on Error {
        message
      }
      ... on AdminQuery {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationQuery {
            searchQuery(text: $text) {
              searchUsers(continuationId: $continuationId, direction: $direction, limit: $limit) {
                continuationId
                totalResultsCount
                pageSize
                users {
                  ...FullOrganizationMemberFragment
                }
              }
            }
          }

          ... on AdminDepartmentQuery {
            searchQuery(text: $text) {
              searchUsers(continuationId: $continuationId, direction: $direction, limit: $limit) {
                continuationId
                totalResultsCount
                pageSize
                users {
                  ...FullOrganizationMemberFragment
                }
              }
            }
          }

          ... on AdminSiteQuery {
            searchQuery(text: $text) {
              searchUsers(continuationId: $continuationId, direction: $direction, limit: $limit) {
                continuationId
                totalResultsCount
                pageSize
                users {
                  ...FullOrganizationMemberFragment
                }
              }
            }
          }
        }
      }
    }
  }

  fragment FullOrganizationMemberFragment on FullOrganizationMember {
    id
    username
    firstname: firstName
    lastname: lastName
    role
    accountStatus
    profilePic: avatar {
      url
    }
    memberStatus
    isDirectorySynced @include(if: $isDirSyncFeatureFlagEnabled)
    isSSOEnabled @include(if: $isDirSyncFeatureFlagEnabled)
    joinDate
    licenseExpiryTime
    licenseStartTime
    scopes {
      organizationId
      siteId
      departmentId
      status
    }
  }
`;
