import gql from 'graphql-tag';
import { ProfileFieldFragmentType } from 'src/gql/v2/fragment/ProfleFieldFragment';

export type AdminAddProfileFieldsMutationResult = {
  adminMutation: {
    organizationalUnit: {
      member: {
        profileSection: {
          addFields: {
            fields: Array<ProfileFieldFragmentType & { id: string }>;
          };
        };
      };
    };
  };
};

export const ADMIN_ADD_PROFILE_FIELDS_MUTATION = gql`
  mutation AddProfileFields(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $uniqueSectionId: ID!
    $fields: [CreateProfileFieldInput!]!
  ) {
    adminMutation {
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminOrganizationMutation {
            member(id: $userId) {
              ... on FullOrganizationMemberMutation {
                profileSection(id: $uniqueSectionId) {
                  addFields(fields: $fields) {
                    ... on ProfileSection {
                      fields {
                        ... on Node {
                          id
                        }
                        ...ProfileFieldFragment
                        __typename
                        ... on ProfilePhoneField {
                          phoneNumber
                          placeholder
                        }
                        ... on ProfileTextField {
                          text
                          placeholder
                        }
                        ... on ProfileDropdownField {
                          options
                          selectedOption
                        }
                        ... on ProfileNumericField {
                          value
                        }
                        ... on ProfileLinkField {
                          url
                        }
                        ... on ProfileEmailField {
                          email
                        }
                        ... on ProfileDateField {
                          date
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment ProfileFieldFragment on ProfileField {
    fieldId
    label
    labelOptions
    visibility
    isSynced
    isEditable
    isRemovable
    isRequired
    validationRules {
      regex
    }
  }
`;
