import React from 'react';
import { UserMenuOptionStatus } from 'src/types';
import styled from 'styled-components';
import { typedUseSelector } from 'src/redux/store';
import { ORGANIZATION } from 'src/constants/organizationTypes';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import { isSTAFeatureFlagEnabled } from '../../../utils/sta/util';

const AccountMgtHeaderMenu = styled.div<{ adminCoSignInvites: boolean; paginatedContactsFeatureFlag: boolean }>`
  display: grid;
  grid-gap: 10px;
  text-align: center;
  grid-auto-rows: minmax(50px, auto);
  color: ${(props) => props.theme.mainFontColor};
  font-size: 18px;
  grid-template-columns: ${(props) => (props.paginatedContactsFeatureFlag ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)')};
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
`;

const AccountMgtHeaderMenuButton = styled.div<{ isSelected: boolean }>`
  align-self: center;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.4px;
  height: 100%;

  &:hover {
    color: ${(props) => props.theme.mainFontColor};
  }

  color: ${(props) => (props.isSelected ? props.theme.mainButtonColor : 'auto')} !important;
  border-bottom: ${(props) => (props.isSelected ? `3px solid ${props.theme.mainButtonColor}` : 'unset')} !important;

  span {
    font-weight: ${(props) => (props.isSelected ? 600 : 'auto')};
  }
`;

interface Props {
  currentMenuOption: UserMenuOptionStatus;
  setMenuOptions: React.Dispatch<React.SetStateAction<UserMenuOptionStatus>>;
  usersCount: number;
  blackListedUsersCount: number;
  pendingUsersCount: number;
  adminCoSignInvites: boolean;
}

const AccountManagementHeader = ({
  usersCount,
  blackListedUsersCount,
  pendingUsersCount,
  currentMenuOption,
  setMenuOptions,
  adminCoSignInvites,
}: Props) => {
  const allUsersCount = usersCount + blackListedUsersCount + pendingUsersCount;
  const unlicensedUsersCount = pendingUsersCount + blackListedUsersCount;
  const currentOrganizationType = typedUseSelector((state) => state.organizationReducer.type);
  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);
  const staFlag = isSTAFeatureFlagEnabled();

  const handleMenuOption = (tab: UserMenuOptionStatus) => {
    setMenuOptions(tab);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.accountUserTabs,
      params: {
        tab_name: tab,
      },
    });
  };

  return (
    <AccountMgtHeaderMenu
      adminCoSignInvites={adminCoSignInvites}
      paginatedContactsFeatureFlag={paginatedContactsFeatureFlag}
    >
      <AccountMgtHeaderMenuButton
        isSelected={currentMenuOption === 'licensed'}
        onClick={() => handleMenuOption('licensed')}
      >
        <span>Licensed Users ({usersCount})</span>
      </AccountMgtHeaderMenuButton>
      {currentOrganizationType === ORGANIZATION && (
        <React.Fragment>
          {adminCoSignInvites ? (
            <>
              <AccountMgtHeaderMenuButton
                isSelected={currentMenuOption === 'pending'}
                onClick={() => handleMenuOption('pending')}
              >
                <span>
                  {staFlag ? 'Pending Approval' : 'Pending Invites'} ({pendingUsersCount})
                </span>
              </AccountMgtHeaderMenuButton>
              <AccountMgtHeaderMenuButton
                isSelected={currentMenuOption === 'unlicensed'}
                onClick={() => handleMenuOption('unlicensed')}
              >
                <span>Removed Users ({blackListedUsersCount})</span>
              </AccountMgtHeaderMenuButton>
            </>
          ) : (
            <>
              <AccountMgtHeaderMenuButton
                isSelected={currentMenuOption === 'unlicensed'}
                onClick={() => handleMenuOption('unlicensed')}
              >
                <span>Unlicensed Users ({unlicensedUsersCount})</span>
              </AccountMgtHeaderMenuButton>
            </>
          )}
          {!paginatedContactsFeatureFlag && (
            <AccountMgtHeaderMenuButton
              isSelected={currentMenuOption === 'all'}
              onClick={() => handleMenuOption('all')}
            >
              <span>All Users ({allUsersCount})</span>
            </AccountMgtHeaderMenuButton>
          )}
        </React.Fragment>
      )}
    </AccountMgtHeaderMenu>
  );
};

export default AccountManagementHeader;
