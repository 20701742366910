import * as React from 'react';

export default (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 10 10"
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    onClick={props.onClick}
  >
    <path
      d="M9.5625 1.3125C9.80412 1.07087 9.80412 0.679124 9.5625 0.4375V0.4375C9.32088 0.195875 8.92913 0.195875 8.6875 0.4375L5 4.125L1.3125 0.4375C1.07087 0.195875 0.679124 0.195875 0.4375 0.4375V0.4375C0.195875 0.679124 0.195875 1.07088 0.4375 1.3125L4.125 5L0.4375 8.6875C0.195875 8.92913 0.195876 9.32088 0.4375 9.5625V9.5625C0.679124 9.80412 1.07087 9.80412 1.3125 9.5625L5 5.875L8.6875 9.5625C8.92913 9.80412 9.32088 9.80412 9.5625 9.5625V9.5625C9.80412 9.32088 9.80412 8.92913 9.5625 8.6875L5.875 5L9.5625 1.3125Z"
      fill={props.color}
    />
  </svg>
);
