import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import 'src/assets/styles/FormStyles.scss';
import { ALREADY_EXIST, USERNAME_ALREADY_EXIST, USER_NOT_FOUND } from 'src/constants/formErrors';
import MultiUserInputDropdownContainer from 'src/components/shared/MultiUsernameInputSelectContainer';
import InviteMultipleUsersContainer from 'src/components/shared/MultiUserInputPaginatedDropdownContainer';
import MultiUserInputPaginatedDropdownContainerSTA from '../../shared/sta/MultiUserInputPaginatedDropdownContainerSTA';

const existingUserValidationSchema = () => {
  return Yup.object().shape({
    username: Yup.array().min(1, 'Please select at least one user').required('Please select at least one user'),
  });
};

export const ExistingUserFormSTA = ({ handleModalFormSubmission, organizationType, paginatingContactsFeatureFlag }) => (
  <Formik
    initialValues={{
      username: [],
    }}
    validationSchema={existingUserValidationSchema()}
    onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
      const res = await handleModalFormSubmission(values);
      console.log(res, 'res');

      if (res === 'success') {
        setSubmitting(false);
        resetForm();
      }

      if (res === 'failed') {
        setSubmitting(false);
        resetForm();
      }
    }}
    render={(props) => {
      const { touched, errors, isSubmitting, handleSubmit, setFieldValue, values } = props as FormikProps<any>;
      const hasErrors = Object.keys(errors).length > 0;
      return (
        <form onSubmit={handleSubmit} className="modalForm">
          {paginatingContactsFeatureFlag ? (
            <MultiUserInputPaginatedDropdownContainerSTA setFieldValue={setFieldValue} />
          ) : (
            <MultiUserInputDropdownContainer
              setFieldValue={setFieldValue}
              userNameArrError={touched.username && Boolean(errors.username)}
            />
          )}
          {isSubmitting && <span className="modalForm__sendRequestLabel">sending request...</span>}
          <div className={`modalForm__buttonWrapper ${hasErrors || isSubmitting ? 'not-allowed' : ''}`}>
            <button type="submit" disabled={hasErrors || isSubmitting}>
              {`Add to ${organizationType[0].toUpperCase() + organizationType.substring(1)} (${
                values.username.length
              })`}
            </button>
          </div>
        </form>
      );
    }}
  />
);
