import { AuthPayload, Client } from './Auth';

export interface SavedOrganization {
  accessToken: string | null;
  accessTokenExpiresAt: string | null;
  client?: Client;
  refreshToken: string | null;
  organization: Organization;
  firstLogin?: false;
  refreshTokenExpiresAt: string | null;
  scopeToken: string;
  sessionID?: string;
  pushNotificationKey?: string;
  user: {
    email?: string;
    firstname: string;
    id: string;
    lastname: string;
    username?: string;
    eulaStatus?: boolean;
    workStatus?: string;
    role?: string;
    loginMethods: string[];
  };
}

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  profilePic?: string;
  email?: string;
}

export interface Organization {
  url: string;
  name: string;
  logo?: string;
  loginMethods: string[];
  imageURL?: string;
  id: number;
}

type MemberAccountStatus = 'active' | 'inactive' | 'blocked' | 'archived';

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
}

export type OrganizationLoginMethods = 'sso' | 'otp' | 'password';

export interface BasicUser {
  id: string;
  firstName: string;
  lastName: string;
  avatar: {
    url: string;
  };
  memberStatus: MemberAccountStatus;
  loginScopes: OrganizationLoginMethods[];
  ssoProfiles?: {
    organization: {
      name: string;
      url: string;
    };
    auth0Id: string;
    ssoId: string;
    ssoVendor: string;
    domain: string;
  }[];
  __typename: string;
}

export interface TokenExchangeGrantRequestResponse {
  access_token: string;
  expires_at: string;
  identity: {
    __typename: string;
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    eulaStatus: boolean;
    workStatus: string;
  };
  issued_token_type: string;
  refresh_token: string;
  scope: string[];
  token_type: string;
  error?: string;
  error_description?: string;
}

export interface OrganizationAccountsCacheData {
  selectedAccountUserId: string;
  savedOrganizations: SavedOrganization[];
}

export interface RefreshTokenRequestResponse {
  data?: TokenExchangeGrantRequestResponse;
  error?: string;
}

export type UpdateAccountInformationWithRefreshTokenData = Pick<
  SavedOrganization,
  'accessToken' | 'accessTokenExpiresAt' | 'refreshToken'
>;

export enum OrgLoginMethods {
  PASSWORD = 'password',
  SSO = 'sso',
  OTP = 'otp',
  LOGIN = 'login',
}
