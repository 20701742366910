import React from 'react';
import GlobalHeader from 'src/components/headers/GlobalHeader';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import VirtualPagersPage from 'src/pages/VirtualPagersPage/VirtualPagersCoordinator';
import SchedulingPage from 'src/pages/SchedulingPage';
import HomePage from 'src/pages/HomePage';
import LoginPage from 'src/pages/LoginPage/index';
import LoginPageSTA from 'src/pages/LoginPage/LoginPageSTA';
import UserProfilePage from 'src/pages/UserProfilePage';
import ProtectedRoute from 'src/router/ProtectedRoute';
import SSORedirectLoadingPage from 'src/pages/SSORedirectLoadingPage';
import AnalyticsDemoPage from 'src/pages/AnalyticsDemoPage/AnalyticsDemoPage';
import EscalationPage from 'src/pages/EscalationPage';
import TemplateReport from 'src/pages/AnalyticsDemoPage/AdminReports/TemplateReport';
import { AppRoutes } from './AppRoutes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SUPPORTED_AUTH_REGIONS } from 'src/types';
import { isSTAFeatureFlagEnabled } from '../utils/sta/util';
import GlobalHeaderSTA from '../components/headers/sta/GlobalHeaderSTA';

// const isProd = process.env.REACT_APP_ENV === 'production';

// Hack to expose the router on the global window scope to use outside of react
class ExposeGlobalRouter extends React.Component {
  public componentDidMount() {
    if (!window.routerHistory) {
      this.setGlobalHistory();
    }
  }

  public componentDidUpdate() {
    if (!window.routerHistory) {
      this.setGlobalHistory();
    }
  }

  public render() {
    return null;
  }

  private setGlobalHistory() {
    // @ts-ignore
    const { history } = this.props;
    window.routerHistory = history;
  }
}

const Routes = () => {
  const { dynamicMaintenancePage } = useFlags();

  const showMaintenancePage =
    SUPPORTED_AUTH_REGIONS.every((element) => dynamicMaintenancePage?.currentMaintenanceRegions?.includes(element)) ||
    dynamicMaintenancePage?.currentMaintenanceRegions?.length === 0;

  const Login = isSTAFeatureFlagEnabled() ? LoginPageSTA : LoginPage;

  const Header = isSTAFeatureFlagEnabled() ? GlobalHeaderSTA : GlobalHeader;

  return (
    <BrowserRouter>
      {showMaintenancePage ? (
        (window.location.href = dynamicMaintenancePage.maintenancePageUrl)
      ) : (
        <React.Fragment>
          <Route path="*" component={ExposeGlobalRouter} />
          <Header />
          <Switch>
            <ProtectedRoute exact path={AppRoutes.Home} component={HomePage} />
            <ProtectedRoute exact path={`${AppRoutes.Home}/audit`} component={HomePage} />
            <ProtectedRoute exact path={AppRoutes.VirtualPagers} component={VirtualPagersPage} />
            <ProtectedRoute exact path={`${AppRoutes.VirtualPagers}/:pagerId`} component={VirtualPagersPage} />
            <ProtectedRoute exact path={AppRoutes.Scheduling} component={SchedulingPage} />
            <ProtectedRoute path={`${AppRoutes.UserProfile}/:userId`} component={UserProfilePage} />
            <ProtectedRoute exact path={AppRoutes.Dashboard} component={AnalyticsDemoPage} />
            <ProtectedRoute exact path={AppRoutes.TemplateReport} component={TemplateReport} />
            <ProtectedRoute exact path={AppRoutes.Escalation} component={EscalationPage} />
            <ProtectedRoute exact path={`${AppRoutes.Escalation}/:escalationLadderId`} component={EscalationPage} />
            <Route exact path={AppRoutes.Login} component={Login} />
            <Route exact path={AppRoutes.SsoRedirect} component={SSORedirectLoadingPage} />
            <Redirect to={AppRoutes.Login} />
          </Switch>
        </React.Fragment>
      )}
    </BrowserRouter>
  );
};

export default Routes;
