import React from 'react';
import { connect } from 'react-redux';
import Logo from 'src/assets/static/hcLogo.png';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import OrganizationSwitcher from 'src/components/headers/global-header/OrganizationSwitcher';
import UserOptions from 'src/components/headers/global-header/UserOptions';
import { RootState } from 'src/redux/store';
import { UserOrganizationSwitcherPayload } from 'src/types';
import 'src/assets/styles/HeaderStyles.scss';
import { getSelfInfo } from 'src/utils/getLocalAuth';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { NEW, VIRTUAL_PAGERS } from 'src/constants/virtualPagerStrings';
import { RedTag } from '../../shared/HypercareComponents';
import { AppRoutes } from '../../../router/AppRoutes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AnnouncementBanner from '../../PageBanner/AnnouncementBanner';
import AccountSwitchDropdownContainer from '../../../pages/NewLoginPage/AccountSwitch/AccountSwitchDropdownContainer';

const GlobalHeaderLeftSection = () => {
  return (
    <>
      <AccountSwitchDropdownContainer />
    </>
  );
};

interface GlobalHeaderRightSectionProps {
  history: any;
  currentOrganization: UserOrganizationSwitcherPayload;
}

const GlobalHeaderRightSection = ({ history, currentOrganization }: GlobalHeaderRightSectionProps) => {
  const { id } = getSelfInfo();
  const VirtualPagerFlag = IsFeatureFlagEnabled('virtualPagerNavigationTab');

  return (
    <div className="globalHeader__userSection">
      <div className="globalHeader__userNavbtn" onClick={() => history.push(AppRoutes.Home)}>
        <span>Users</span>
      </div>
      <div className="globalHeader__userNavbtn" onClick={() => history.push(AppRoutes.Dashboard)}>
        <span>Analytics</span>
      </div>
      {VirtualPagerFlag && (
        <div
          className="globalHeader__userNavbtn"
          onClick={() => {
            history.push(AppRoutes.VirtualPagers);
          }}
        >
          <span>{VIRTUAL_PAGERS}</span>
          <RedTag>{NEW}</RedTag>
        </div>
      )}
      <div className="globalHeader__userNavbtn" onClick={() => history.push(AppRoutes.Scheduling)}>
        <span>Schedules</span>
      </div>
      <UserOptions />
    </div>
  );
};

interface GlobalHeaderProps extends RouteChildrenProps {
  isAuthorized: boolean;
  currentOrganization: UserOrganizationSwitcherPayload;
  isWaiting: boolean;
}

const GlobalHeader = ({ history, isAuthorized, currentOrganization }: GlobalHeaderProps) => {
  const { announcementBanner } = useFlags();

  if (isAuthorized) {
    return (
      <>
        <div className="globalHeader" id="globalHeader">
          <GlobalHeaderLeftSection />
          <GlobalHeaderRightSection history={history} currentOrganization={currentOrganization} />
        </div>
        {Boolean(Object.entries(announcementBanner || {}).length) && <AnnouncementBanner />}
      </>
    );
  }
  return null;
};

const mapStateToProps = (state: RootState) => {
  return {
    isWaiting: state.flagReducer.isWaitingSwitchOrganization,
    isAuthorized: state.flagReducer.isAuthorized,
    currentOrganization: state.organizationReducer,
  };
};

export default connect(mapStateToProps)(withRouter(GlobalHeader));
