import gql from 'graphql-tag';

export interface AddMemberToScopeSTAMutation {
  adminMutation: {
    organizationalUnit: {
      message?: string;
      addMember: {
        id: string;
        firstName: string;
        lastName: string;
        __typename: string;
      };
      __typename: string;
    };
  };
}

export const ADD_MEMBER_TO_SCOPE_STA_MUTATION = gql`
  mutation addMemberToScopeSTA($organizationalUnit: OrganizationalUnitInput!, $id: ID!) {
    adminMutation {
      __typename
      ... on Error {
        __typename
        message
      }
      ... on AdminMutation {
        organizationalUnit(organizationalUnit: $organizationalUnit) {
          ... on AdminSiteMutation {
            addMember(id: $id) {
              ...FullOrganizationMemberFragment

              ... on Error {
                __typename
                message
              }
            }
          }
          ... on AdminDepartmentMutation {
            addMember(id: $id) {
              ...FullOrganizationMemberFragment

              ... on Error {
                __typename
                message
              }
            }
          }
          ... on Error {
            __typename
            message
          }
        }
      }
    }
  }

  fragment FullOrganizationMemberFragment on FullOrganizationMember {
    id
    firstName
    lastName
  }
`;
