import React from 'react';
import moment from 'moment';
import { QueryResult } from 'react-apollo';
import client from 'src/clients/apolloClient';
import { GET_USERS_WITH_SCOPES } from 'src/gql/query/GetUsersQuery';
import { INVITE_EXIST_USER, INVITE_NEW_USER, CREATE_SHELL_USER } from 'src/gql/mutation/InviteUserMutation';
import ExistingUserForm from 'src/components/forms/ExistingUserForm';
import NewUserForm from 'src/components/forms/NewUserForm';
import { toast } from 'react-toastify';
import { EXISTING, NEW } from 'src/constants/inviteUserTypes';
import { ALREADY_EXIST, USER_NOT_FOUND, BACKEND_ALREADY_EXIST } from 'src/constants/formErrors';
import AnalyticsManager, { EVENTS, PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import {
  UserAddressType,
  User,
  GetUsersResult,
  UserOrganizationSwitcherPayload,
  UserMenuOptionStatus,
  GetAllLicensingUsersResult,
  GetPendingUsersResult,
} from 'src/types';
import { ORGANIZATION } from 'src/constants/organizationTypes';
import InviteOrganizationScopeManager from 'src/components/modals/invite-user/InviteOrganizationScopeManager';
import ModalFormSelection from 'src/components/modals/invite-user/InviteUserSelection';
import SetUserLicenseExpiryMutation from 'src/gql/mutation/SetUserLicenseExpiryMutation';
import CustomToaster from 'src/components/CustomToaster';
import CheckSuccess from 'src/assets/svgs/CheckSuccess';
import store from 'src/redux/store';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { FeatureFlagResult } from 'src/utils/FeatureFlags';
import InviteOrganizationScopeManagerSTA from './InviteOrganizationScopeManagerSTA';
import NewUserSTAForm from 'src/components/forms/NewUserSTAForm';
import { CREATE_LICENSED_ACCOUNT_V2 } from 'src/gql/v2/mutation/CreateLicensedAccountMutation';
import CreateShellAccountMutation from 'src/gql/v2/mutation/CreateShellAccountMutation';
import { checkInputType, isSTAFeatureFlagEnabled } from 'src/utils/sta/util';
import { FetchPaginatedUsersQueryResponse } from '../../../gql/v2/query/FetchPaginatedUsersQuery';
import { FetchPaginatedUsersQueryResponseSTA } from '../../../gql/v2/query/sta/FetchPaginatedUsersQuerySTA';
import { ExistingUserFormSTA } from '../../forms/sta/ExistingUserFormSTA';
import { InviteUserViewModel } from '../../viewModels/InviteUserViewModel';
import { checkOrganizationalUnit } from 'src/utils/getOrganizationalUnitObject';

interface NewUserFormValue {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  role: string;
  isShellAccount: boolean;
  licenseStartDate: moment.Moment;
  licenseEndDate: moment.Moment;
}

interface ExistingUserFormValue {
  username: string[];
}

interface Props {
  closeModal: () => void;
  userFullNameLists: string[];
  currentOrganization: UserOrganizationSwitcherPayload;
  setMenuOptions: React.Dispatch<React.SetStateAction<UserMenuOptionStatus>>;
  userRecords: QueryResult<
    FetchPaginatedUsersQueryResponse | GetAllLicensingUsersResult | FetchPaginatedUsersQueryResponseSTA
  >;
  pendingRecords?: QueryResult<
    GetPendingUsersResult | FetchPaginatedUsersQueryResponse | FetchPaginatedUsersQueryResponseSTA
  >;
  onAddUserSuccess: () => void;
  setInviteResponse: React.Dispatch<React.SetStateAction<any[]>>;
  setAddressesInput: React.Dispatch<React.SetStateAction<any[]>>;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// TODO: revamp invite process and components

const InviteUserActionContainer = ({
  closeModal,
  userFullNameLists,
  currentOrganization,
  setMenuOptions,
  userRecords,
  pendingRecords,
  onAddUserSuccess,
  setInviteResponse,
  setAddressesInput,
  setShowResponseModal,
}: Props) => {
  const { site_id, department_id, type, organization_id } = store.getState().organizationReducer;
  const [currentFormType, setCurrentFormType] = React.useState<'existing' | 'new'>(EXISTING);
  const { handleAddAllMembersToScope } = InviteUserViewModel();

  const adminCoSignInvites =
    IsFeatureFlagEnabled(FeatureFlagResult.adminCoSignInvites) && site_id === null && department_id === null;
  const STAFlag = isSTAFeatureFlagEnabled();
  const paginatingContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  const handleModalFormSubmission = (formValue: NewUserFormValue | ExistingUserFormValue) => {
    return new Promise((resolve, reject) => {
      const isUserExist = userFullNameLists?.find((username) => username === formValue.username);

      if (isUserExist) {
        reject(ALREADY_EXIST);
        toast.dismiss();
        toast.error('User Already Exist!', {
          className: 'Toast-Container',
          autoClose: false,
        });
        return;
      }

      if (currentFormType === EXISTING) {
        const newState = Object.assign(
          {
            username: [] as string[],
          },
          formValue,
        );

        handleInviteExistingUser(newState)
          .then(() => {
            onAddUserSuccess();
            resolve('success');
          })
          .catch((e) => {
            console.error(e);
            if (e.message === USER_NOT_FOUND) reject(USER_NOT_FOUND);
            reject('failed');
          });
      } else {
        const newState = Object.assign({}, formValue) as NewUserFormValue;

        handleCreateNewUser(newState)
          .then(async (userID) => {
            onAddUserSuccess();
            // confirm with backend of why not bind license end time to the InviteNewUser directly
            try {
              await client.mutate({
                mutation: SetUserLicenseExpiryMutation,
                variables: {
                  userId: userID,
                  licenseStart: newState.licenseStartDate,
                  licenseEnd: newState.licenseEndDate,
                },
              });
              resolve('success');
            } catch (error) {
              // does not catch error for set license time
              console.error(error);
              resolve('success');
            }
            AnalyticsManager.applyAnalytics({
              eventName: EVENTS.createNewUserButtonPressed,
              params: {
                username: newState.username,
                first_name: newState.firstname,
                last_name: newState.lastname,
                email_id: newState.email,
                phone_number: newState.phonenumber,
                is_shell_accout: newState.isShellAccount,
                role: newState.role,
                license_end_date: newState.licenseEndDate ? newState.licenseEndDate.toISOString() : null,
              },
            });
          })
          .catch((e) => {
            if (e.message === ALREADY_EXIST) reject(ALREADY_EXIST);
            reject('failed');
          });
      }
    });
  };

  const handleModalFormSubmissionSTA = async (formValue: { username: string[] }) => {
    const userIds = formValue?.username || [];

    try {
      const res = await handleAddAllMembersToScope({ userIds });

      if (res.success.length > 0) {
        toast.success(
          ` ${res.success.length} 
            member${res.success.length > 1 ? 's' : ''} has been added`,
          {
            className: 'Toast-Container',
            autoClose: false,
          },
        );
      }

      if (res.fail.length > 0) {
        toast.error(
          `  ${res.fail.length} 
            member${res.fail.length > 1 ? 's' : ''} have failed to been added`,
          {
            className: 'Toast-Container',
            autoClose: false,
          },
        );
      }

      onAddUserSuccess();
      closeModal();
      return 'success';
    } catch (error) {
      console.error('Error when inviting user:', error);
      toast.error(` Error when inviting user`, {
        className: 'Toast-Container',
        autoClose: false,
      });
      throw 'failed';
    }
  };

  async function handleInviteExistingUser(invitedUser: ExistingUserFormValue) {
    const usernameList = invitedUser.username;
    let successAddedUsernames: string[] = [];
    let successUsers: User[] = [];

    const inviteUser = async (username: string) => {
      try {
        const result = await client.mutate({
          mutation: INVITE_EXIST_USER,
          variables: { username },
        });
        successAddedUsernames.push(username);
        successUsers.push(result.data.admin.addUser);
      } catch (e) {
        console.error(e);
        toast.error(`Error when inviting user with username ${username}`, {
          className: 'Toast-Container',
        });
      }
    };

    await Promise.all(usernameList.map((username) => inviteUser(username)));

    if (!paginatingContactsFeatureFlag) {
      const queryUsers: GetUsersResult = client.readQuery({ query: GET_USERS_WITH_SCOPES });
      client.writeQuery({
        query: GET_USERS_WITH_SCOPES,
        data: {
          admin: {
            ...queryUsers.admin,
            users: [...queryUsers.admin.users, ...successUsers],
          },
        },
      });
    }

    if (successAddedUsernames.length > 0) {
      toast.success(
        `${successAddedUsernames.length} 
        member${successAddedUsernames.length > 1 ? 's' : ''} has been added`,
        {
          className: 'Toast-Container',
          autoClose: false,
        },
      );
    }
    closeModal();
  }

  const handleCreateAccountFormSubmission = (formValue: NewUserFormValue): Promise<void> => {
    return new Promise((resolve, reject) => {
      const newState = Object.assign({}, formValue) as NewUserFormValue;
      handleCreateNewUserSTA(newState)
        .then(async (userID) => {
          onAddUserSuccess();

          AnalyticsManager.applyAnalytics({
            eventName: EVENTS.createNewUserButtonPressed,
            params: {
              first_name: newState.firstname,
              last_name: newState.lastname,
              email_id: newState.email,
              phone_number: newState.phonenumber,
              is_shell_accout: newState.isShellAccount,
              role: newState.role,
              license_end_date: newState.licenseEndDate ? newState.licenseEndDate.toISOString() : null,
            },
          });
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  };

  async function handleCreateNewUserSTA(newUser: NewUserFormValue) {
    const { firstname, lastname, email, phonenumber, role, isShellAccount, licenseEndDate } = newUser;

    const addresses = [
      {
        address: email,
        type: 'email',
      },
    ];

    if (Boolean(phonenumber)) {
      addresses.push({
        address: `+1${phonenumber}`,
        type: 'phoneNumber' as UserAddressType,
      });
    }

    const accounts = [
      {
        profile: {
          firstName: firstname,
          lastName: lastname,
          role: Boolean(role) ? role : undefined,
        },
        addresses,
        licenseExpiryDate: licenseEndDate ? licenseEndDate.toISOString() : null,
      },
    ];

    try {
      const userName = firstname + ' ' + lastname;
      if (isShellAccount) {
        const response = await client.mutate({
          mutation: CreateShellAccountMutation,
          variables: {
            organizationalUnit: checkOrganizationalUnit(),
            accounts,
          },
        });
        processResponseToast(
          isShellAccount,
          response?.data.adminMutation.organizationalUnit.createShellAccounts.accounts?.[0],
          userName,
        );
      } else {
        const response = await client.mutate({
          mutation: CREATE_LICENSED_ACCOUNT_V2,
          variables: {
            organizationalUnit: checkOrganizationalUnit(),
            accounts,
          },
        });
        processErrors(response?.data.adminMutation.organizationalUnit.createAccounts.accounts?.[0]);
        processResponseToast(
          isShellAccount,
          response?.data.adminMutation.organizationalUnit.createAccounts.accounts?.[0],
          userName,
        );
      }
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async function handleCreateNewUser(newUser: NewUserFormValue) {
    const { username, firstname, lastname, email, phonenumber, role, isShellAccount } = newUser;
    const addresses = [{ address: email, type: 'email' as UserAddressType }];

    if (Boolean(phonenumber)) {
      addresses.push({
        address: `+1${phonenumber}`,
        type: 'phoneNumber' as UserAddressType,
      });
    }

    const variables = {
      username,
      firstname,
      lastname,
      role: Boolean(role) ? role : undefined,
      addresses,
    };

    try {
      let userID: string;
      let userName: string;
      if (isShellAccount) {
        await client.mutate({
          mutation: CREATE_SHELL_USER,
          variables,
          update: (cache, { data }) => {
            userID = data.admin.createShellAccount.id;
            userName = data.admin.createShellAccount.firstname + ' ' + data.admin.createShellAccount.lastname;
            updateCache(cache, data, true);
          },
        });
      } else {
        await client.mutate({
          mutation: INVITE_NEW_USER,
          variables,
          update: (cache, { data }) => {
            userID = data.admin.createUser.id;
            userName = data.admin.createUser.firstname + ' ' + data.admin.createUser.lastname;
            updateCache(cache, data, false);
          },
        });
      }
      successUserInvite(isShellAccount, userName);
      return Promise.resolve(userID);
    } catch (e) {
      if (e.networkError && e.networkError.result && e.networkError.result.errors) {
        const errorName = e.networkError.result.errors[0].name;
        if (errorName === BACKEND_ALREADY_EXIST) {
          // set field error to username
          errorUserInvite();
          throw new Error(ALREADY_EXIST);
        } else {
          errorUserInvite(e.networkError.result.errors[0].message);
          throw new Error(e);
        }
      }
      errorUserInvite();
      throw new Error(e);
    }
  }

  const processErrors = (response: any) => {
    switch (response.__typename) {
      case 'AddressInUse':
      case 'AccountBlocked':
      case 'Unknown error':
        if (checkInputType(response?.address) === 'email') {
          throw 'EmailInUse';
        } else if (checkInputType(response?.address) === 'phone') {
          throw 'PhoneInUse';
        } else {
          throw 'Unknown error';
        }
    }
  };

  const processResponseToast = (isShell: boolean, response: any, userName: string) => {
    switch (response.__typename) {
      case 'FullOrganizationMember':
      case 'InvitePendingAdminApproval':
        toast.info(
          isShell ? (
            'Shell account has been added to the organization'
          ) : adminCoSignInvites ? (
            <CustomToaster
              logo={<CheckSuccess />}
              title={`New user “${userName}” has been created.`}
              body={`This user will automatically become licensed and can begin 
            using Hypercare once they log in with the provided credentials.`}
            />
          ) : (
            'User has been added'
          ),
          {
            className: 'Toast-Container',
          },
        );
        break;
      case 'AddressInUse':
      case 'AccountBlocked':
      case 'Unknown error':
        toast.error(response.message ? response.message : 'Error when inviting user', {
          className: 'Toast-Container',
          autoClose: false,
        });
        throw new Error(response.__typename);
    }

    userRecords.refetch();
    closeModal();
    if (adminCoSignInvites) {
      setMenuOptions('licensed');
    }
  };

  const successUserInvite = (isShell: boolean, userName: string) => {
    toast.info(
      isShell ? (
        'Shell account has been added to the organization' // TODO: and an invite to join Hypercare has been sent
      ) : adminCoSignInvites ? (
        <CustomToaster
          logo={<CheckSuccess />}
          title={`New user “${userName}” has been created.`}
          body={`This user will automatically become licensed and can begin 
        using Hypercare once they log in with the provided credentials.`}
        />
      ) : (
        'User has been added'
      ),
      {
        className: 'Toast-Container',
      },
    );
    userRecords.refetch();
    closeModal();
    if (adminCoSignInvites) {
      setMenuOptions('licensed');
    }
  };

  const errorUserInvite = (message?: string) => {
    toast.dismiss();
    toast.error(message ? message : 'Error when inviting user', {
      className: 'Toast-Container',
      autoClose: false,
    });
  };

  const setFormType = (newType: 'existing' | 'new') => {
    if (currentFormType !== newType) {
      AnalyticsManager.recordPageVisited(
        newType === NEW ? PAGE_VIEWS.inviteNewMemberView : PAGE_VIEWS.inviteExistingMemberView,
      );
      setCurrentFormType(newType);
      AnalyticsManager.applyAnalytics({
        eventName: newType === NEW ? EVENTS.createNewUserTabPressed : EVENTS.inviteByEmailOrPhoneTabPressed,
      });
    }
  };

  function updateCache(cache, data, isShellUser) {
    // TODO: backend does not allow direct invite to site or department yet
    if (isShellUser && currentOrganization.type !== ORGANIZATION) return;

    const queryUsers: GetUsersResult = cache.readQuery({ query: GET_USERS_WITH_SCOPES });
    let newUser: User;

    if (isShellUser) {
      newUser = data.admin.createShellAccount;
    } else if (currentFormType === NEW) {
      newUser = data.admin.createUser;
    }

    cache.writeQuery({
      query: GET_USERS_WITH_SCOPES,
      data: {
        admin: {
          ...queryUsers.admin,
          users: [
            ...queryUsers.admin.users,
            {
              ...newUser,
              __typename: 'FullUser',
            },
          ],
        },
      },
    });
  }

  return (
    <React.Fragment>
      <ModalFormSelection
        setFormType={setFormType}
        currentFormType={currentFormType}
        organizationType={currentOrganization.type}
      />
      {currentFormType === NEW ? (
        STAFlag ? (
          <NewUserSTAForm handleModalFormSubmission={handleCreateAccountFormSubmission} closeModal={closeModal} />
        ) : (
          <NewUserForm handleModalFormSubmission={handleModalFormSubmission} closeModal={closeModal} />
        )
      ) : currentOrganization.type === ORGANIZATION ? (
        STAFlag ? (
          <InviteOrganizationScopeManagerSTA
            closeModal={closeModal}
            setMenuOptions={setMenuOptions}
            pendingRecords={pendingRecords}
            setInviteResponse={setInviteResponse}
            setAddressesInput={setAddressesInput}
            setShowResponseModal={setShowResponseModal}
          />
        ) : (
          <InviteOrganizationScopeManager
            closeModal={closeModal}
            setMenuOptions={setMenuOptions}
            pendingRecords={pendingRecords}
          />
        )
      ) : STAFlag ? (
        <ExistingUserFormSTA
          handleModalFormSubmission={handleModalFormSubmissionSTA}
          organizationType={currentOrganization.type}
          paginatingContactsFeatureFlag={paginatingContactsFeatureFlag}
        />
      ) : (
        <ExistingUserForm
          handleModalFormSubmission={handleModalFormSubmission}
          organizationType={currentOrganization.type}
          paginatingContactsFeatureFlag={paginatingContactsFeatureFlag}
        />
      )}
    </React.Fragment>
  );
};

export default InviteUserActionContainer;
