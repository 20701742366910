import React, { useEffect } from 'react';
import UserProfileLayout from 'src/pages/UserProfilePage/UserProfileLayout';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { IsFeatureFlagEnabled } from '../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../utils/FeatureFlags';
import { UserProfileLayoutLicensingContainer } from './sta/UserProfileLayoutSTA';
import { isSTAFeatureFlagEnabled } from '../../utils/sta/util';

const UserProfilePage = () => {
  const STAFlag = isSTAFeatureFlagEnabled();

  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.userProfileView);
  }, []);

  return <div className="appContent">{STAFlag ? <UserProfileLayoutLicensingContainer /> : <UserProfileLayout />}</div>;
};

export default UserProfilePage;
