import { useMutation, useQuery } from 'react-apollo';
import {
  FETCH_PROFILE_FIELDS_FOR_USER,
  FetchUserProfileCustomFieldsQueryResult,
} from 'src/gql/v2/query/FetchProfileFieldsForUser';
import { checkOrganizationalUnit, getOrganizationObject } from 'src/utils/getOrganizationalUnitObject';
import {
  CreateProfileSectionInput,
  OrganizationalUnitInputType,
  UserIdInputType,
  UniqueSectionIdInputType,
  CreateProfileFieldsInput,
  UniqueFieldIdInputType,
  UpdateProfileFieldInput,
} from 'src/gql/v2/types/input';
import {
  ADMIN_ADD_PROFILE_SECTION_MUTATION,
  AdminAddProfileSectionMutationResult,
} from 'src/gql/v2/mutation/AdminAddProfileSectionMutation';
import {
  ADMIN_ADD_PROFILE_FIELDS_MUTATION,
  AdminAddProfileFieldsMutationResult,
} from 'src/gql/v2/mutation/AdminAddProfileFieldsMutation';
import {
  FetchOrgProfileFieldsSchemaQuery,
  FetchOrgProfileFieldsSchemaQueryResult,
} from 'src/gql/v2/query/FetchOrgProfileFieldsSchemaQuery';
import {
  ADMIN_UPDATE_PROFILE_FIELD_MUTATION,
  UpdateProfileFieldMutationResult,
} from 'src/gql/v2/mutation/AdminUpdateProfileFieldMutation';
import {
  ADMIN_DELETE_PROFILE_FIELD_MUTATION,
  DeleteProfileFieldMutationResult,
} from 'src/gql/v2/mutation/AdminDeleteProfileFieldMutation';
import { isSelfUser } from 'src/utils/getLocalAuth';

type FetchUserProfileFieldsError = null | 'networkError' | 'anotherError(complete me)';
type FetchProfileFieldsSchemaError = null | 'networkError' | 'anotherError(complete me)';
type CreateProfileFieldsSectionError = null | 'networkError' | 'anotherError(complete me)';
type CreateProfileFieldsError = null | 'networkError' | 'anotherError(complete me)';
type UpdateProfileFieldError = null | 'networkError' | 'anotherError(complete me)';
type DeleteProfileFieldError = null | 'networkError' | 'anotherError(complete me)';

export const CustomFieldsRepository = () => {
  const useFetchUserProfileFields = (userId: string) => {
    const org = isSelfUser(userId) ? getOrganizationObject() : checkOrganizationalUnit();
    const result = useQuery<FetchUserProfileCustomFieldsQueryResult, OrganizationalUnitInputType & UserIdInputType>(
      FETCH_PROFILE_FIELDS_FOR_USER,
      {
        fetchPolicy: 'no-cache',
        variables: {
          organizationalUnit: org as any,
          userId,
        },
      },
    );

    let error: FetchUserProfileFieldsError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchProfileFieldsSchema = () => {
    const result = useQuery<FetchOrgProfileFieldsSchemaQueryResult, OrganizationalUnitInputType>(
      FetchOrgProfileFieldsSchemaQuery,
      {
        fetchPolicy: 'no-cache',
        variables: {
          organizationalUnit: getOrganizationObject(),
        },
      },
    );

    let error: FetchProfileFieldsSchemaError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useCreateProfileSection = () => {
    const result = useMutation<
      AdminAddProfileSectionMutationResult,
      OrganizationalUnitInputType & UserIdInputType & CreateProfileSectionInput
    >(ADMIN_ADD_PROFILE_SECTION_MUTATION, {
      fetchPolicy: 'no-cache',
    });

    let error: CreateProfileFieldsSectionError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      createProfileFieldsSection: result[0],
      ...result[1],
      error,
    };
  };

  const useCreateProfileFields = () => {
    const result = useMutation<
      AdminAddProfileFieldsMutationResult,
      CreateProfileFieldsInput & OrganizationalUnitInputType & UserIdInputType & UniqueSectionIdInputType
    >(ADMIN_ADD_PROFILE_FIELDS_MUTATION, {
      fetchPolicy: 'no-cache',
    });

    let error: CreateProfileFieldsError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      createProfileFields: result[0],
      ...result[1],
      error,
    };
  };

  const useUpdateProfileField = () => {
    const result = useMutation<
      UpdateProfileFieldMutationResult,
      OrganizationalUnitInputType & UserIdInputType & UniqueFieldIdInputType & { fieldData: UpdateProfileFieldInput }
    >(ADMIN_UPDATE_PROFILE_FIELD_MUTATION, {
      fetchPolicy: 'no-cache',
    });

    let error: UpdateProfileFieldError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      updateProfileField: result[0],
      ...result[1],
      error,
    };
  };

  const useDeleteProfileField = () => {
    const result = useMutation<
      DeleteProfileFieldMutationResult,
      OrganizationalUnitInputType & UserIdInputType & UniqueFieldIdInputType
    >(ADMIN_DELETE_PROFILE_FIELD_MUTATION, {
      fetchPolicy: 'no-cache',
    });

    let error: DeleteProfileFieldError = null;

    if (result[1]?.error?.networkError) {
      error = 'networkError';
    }

    return {
      deleteProfileField: result[0],
      ...result[1],
      error,
    };
  };

  return {
    useFetchUserProfileFields,
    useFetchProfileFieldsSchema,
    useCreateProfileSection,
    useCreateProfileFields,
    useUpdateProfileField,
    useDeleteProfileField,
  };
};
