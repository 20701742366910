import React, { useEffect, useState } from 'react';
import ConfigureNumberHeader from './components/ConfigureNumberHeader';
import { BannerMessage } from 'src/components/shared/BannerMessage';
import styled from 'styled-components';
import { StyledLoader } from 'src/pages/VirtualPagersPage/components/StyledLoader';
import {
  HCBodyOne,
  HCBodySpan,
  HCHeadingThree,
  HCLabelOne,
  HCHeadingFour,
} from 'src/components/shared/HypercareComponents';
import theme from 'src/assets/styles/theme';
import { ContactSupportCenterButton, DisableNumberButton, ReactivateNumberButton } from '../components/buttons';
import ModalDisableNumber from './ModalDisableNumber';
import ModalReactivateNumber from './ModalReactivateNumber';
import FriendlyNameAccordion from './components/Accordion/FriendlyNameAccordion';
import AlertPreferenceAccordion from './components/Accordion/AlertPreferenceAccordion';
import AudioAccordion from './components/Accordion/AudioAccordion';
import AssigneeAccordion from './components/Accordion/AssigneeAccordion';

import { useParams, useLocation } from 'react-router-dom';
import store, { typedUseSelector } from 'src/redux/store';
import { useDispatch } from 'react-redux';
import allActions from 'src/redux/actions';
import ConfigureNumberViewModel from './ConfigureNumberViewModel';
import { VirtualPagerStatusTypes } from 'src/types';
import {
  DISABLE_NUMBER_DANGER_ZONE_TITLE,
  DISABLE_NUMBER_DESCRIPTION_TEXT,
  REACTIVATE_NUMBER_DANGER_ZONE_TITLE,
  REACTIVATE_NUMBER_DESCRIPTION_TEXT,
  RELEASE_NUMBER_DANGER_ZONE_TITLE,
  RELEASE_NUMBER_DESCRIPTION_TEXT,
} from 'src/constants/virtualPagerStrings';
import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';

const ConfigureNumberPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  gap: 50px;
  justify-content: center;
  align-items: center;
`;

const ConfigureNumberPageBodyContainer = styled.div`
  max-width: 920px;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border-grey, #d8d8d8);
`;

const DangerZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  margin-top: 8px;
`;

const DangerZoneInternalContainer = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid var(--border-grey, #d8d8d8);
  justify-content: space-between;
  &:last-child {
    border-bottom: 0px;
  }
`;

interface DangerZoneSectionProps {
  title: string;
  description: string;
  button?: React.ReactNode;
}

const DangerZoneSection: React.FC<DangerZoneSectionProps> = ({ title, description, button }) => (
  <DangerZoneInternalContainer>
    <div>
      <HCHeadingFour color="black">{title}</HCHeadingFour>
      <HCLabelOne style={{ maxWidth: '40em' }}>{description}</HCLabelOne>
    </div>
    <div>{button}</div>
  </DangerZoneInternalContainer>
);

const ConfigureNumberPage: React.FC = () => {
  const location = useLocation();
  const [reactivatePopUp, setReactivatePopUp] = useState<string | null>(
    new URLSearchParams(location.search).get('reactivatePopUp'),
  );

  const dispatch = useDispatch();
  const currentPager = typedUseSelector((state) => state.virtualPagerReducer.currentPager);
  const [isDisableModalVisible, setDisableModalVisible] = useState(false);
  const [isReactivateModalVisible, setReactivateModalVisible] = useState(false);
  const { pagerId } = useParams<{ pagerId: string }>();
  const { fetchPagerById } = ConfigureNumberViewModel();
  const orgId = store.getState()?.organizationReducer?.organization_id;
  const [pagerStatus, setPagerStatus] = useState<string | null>(null);
  const [initialStatusLoaded, setInitialStatusLoaded] = useState(false);

  const fetchNumberInParam = async () => {
    const response = await fetchPagerById(pagerId);
    if (response) {
      dispatch(allActions.virtualPagerAction.setEditedAndCurrentPager(response));
    }
  };

  useEffect(() => {
    fetchNumberInParam();
    return () => {
      dispatch(allActions.virtualPagerAction.setEditedAndCurrentPager(null));
    };
  }, [dispatch, orgId, pagerId]);

  useEffect(() => {
    // If the pager is disabled and the user is trying to reactivate it via the REACTIVATE link, show the reactivate modal
    if (currentPager && currentPager.status === VirtualPagerStatusTypes.DISABLED && reactivatePopUp === 'true') {
      setReactivateModalVisible(true);
      window.history.replaceState(null, '', window.location.pathname);
      setReactivatePopUp(null);
    }
    setPagerStatus(currentPager ? currentPager.status : null);
  }, [currentPager]);

  useEffect(() => {
    // If the pager status changes log a mixpanel event for the new status
    if (currentPager && pagerStatus) {
      if (!initialStatusLoaded) {
        setInitialStatusLoaded(true);
      } else {
        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.pvpaNumberStatusChange,
          params: {
            virtual_pager_number: currentPager.pagerNumber,
            status: pagerStatus,
          },
        });
      }
    }
  }, [pagerStatus]);

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText('support@hypercare.com');
  };

  return currentPager ? (
    <ConfigureNumberPageContainer>
      <ConfigureNumberHeader />
      <ConfigureNumberPageBodyContainer>
        {currentPager.status === VirtualPagerStatusTypes.PENDING ? (
          <BannerMessage
            title={'Complete configuration to activate this number'}
            description={
              <HCBodySpan>
                This virtual number will remain out-of-service until the following configurations are completed.
              </HCBodySpan>
            }
            type="warning"
          />
        ) : currentPager.status === VirtualPagerStatusTypes.DISABLED ? (
          <BannerMessage
            title={'Reactivate number to resume all activities'}
            description={
              <>
                <HCBodySpan>
                  This number was temporarily disabled and is currently out of service. Reactivate now to modify any
                  configurations and resume all activities.
                </HCBodySpan>

                <HCBodyOne
                  style={{ cursor: 'pointer' }}
                  color={theme.mainTealColor}
                  onClick={() => {
                    setReactivateModalVisible(true);
                    AnalyticsManager.applyAnalytics({
                      eventName: EVENTS.pvpaReactivateNumberPressed,
                      params: {
                        virtual_pager_number: currentPager.pagerNumber,
                        source: 'banner',
                      },
                    });
                  }}
                >
                  Reactivate Number
                </HCBodyOne>
              </>
            }
            type="warning"
          />
        ) : null}

        <HCHeadingThree margin="21px 0px" color={theme.black}>
          Configuration
        </HCHeadingThree>

        <AccordionContainer>
          <FriendlyNameAccordion />
          <AssigneeAccordion />
          <AlertPreferenceAccordion />
          <AudioAccordion />
        </AccordionContainer>

        <HCHeadingThree style={{ marginTop: '24px' }} color={theme.black}>
          Danger Zone
        </HCHeadingThree>
        <DangerZoneContainer>
          {currentPager.status !== VirtualPagerStatusTypes.DISABLED ? (
            <DangerZoneSection
              title={DISABLE_NUMBER_DANGER_ZONE_TITLE}
              description={DISABLE_NUMBER_DESCRIPTION_TEXT}
              button={
                <DisableNumberButton
                  isDisabled={currentPager.status !== 'active'}
                  handleClick={() => {
                    setDisableModalVisible(true);
                    AnalyticsManager.applyAnalytics({
                      eventName: EVENTS.pvpaDisableNumberPressed,
                      params: {
                        virtual_pager_number: currentPager.pagerNumber,
                      },
                    });
                  }}
                />
              }
            />
          ) : (
            <DangerZoneSection
              title={REACTIVATE_NUMBER_DANGER_ZONE_TITLE}
              description={REACTIVATE_NUMBER_DESCRIPTION_TEXT}
              button={
                <ReactivateNumberButton
                  isDisabled={false}
                  handleClick={() => {
                    setReactivateModalVisible(true);
                    AnalyticsManager.applyAnalytics({
                      eventName: EVENTS.pvpaReactivateNumberPressed,
                      params: {
                        virtual_pager_number: currentPager.pagerNumber,
                        source: 'danger zone',
                      },
                    });
                  }}
                />
              }
            />
          )}
          <DangerZoneSection
            title={RELEASE_NUMBER_DANGER_ZONE_TITLE}
            description={RELEASE_NUMBER_DESCRIPTION_TEXT}
            button={
              <ContactSupportCenterButton
                isDisabled={false}
                handleClick={() => {
                  copyTextToClipboard();
                  AnalyticsManager.applyAnalytics({
                    eventName: EVENTS.pvpaVirtualPagerContactSupportToReleasePressed,
                    params: {
                      virtual_pager_number: currentPager.pagerNumber,
                    },
                  });
                }}
              />
            }
          />
        </DangerZoneContainer>
      </ConfigureNumberPageBodyContainer>

      <ModalDisableNumber
        pager={currentPager}
        isOpen={isDisableModalVisible}
        closeModal={() => {
          setDisableModalVisible(false);
        }}
      />

      <ModalReactivateNumber
        pager={currentPager}
        isOpen={isReactivateModalVisible}
        closeModal={() => {
          setReactivateModalVisible(false);
        }}
      />
    </ConfigureNumberPageContainer>
  ) : (
    <StyledLoader />
  );
};

export default ConfigureNumberPage;
