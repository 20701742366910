import gql from 'graphql-tag';

export const FullOrganizationMemberFragment = gql`
  fragment FullOrganizationMemberFragment on FullOrganizationMember {
    id
    username
    firstname: firstName
    lastname: lastName
    role
    accountStatus
    profilePic: avatar {
      url
    }
    isDirectorySynced @include(if: $isDirSyncFeatureFlagEnabled)
    isSSOEnabled @include(if: $isDirSyncFeatureFlagEnabled)
    joinDate
    licenseExpiryTime
    licenseStartTime
    scopes {
      organizationId
      siteId
      departmentId
      status
    }
  }
`;
