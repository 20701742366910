import React, { useState } from 'react';
import { connect } from 'react-redux';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { UserOrganizationSwitcherPayload } from 'src/types';
import { RootState, typedUseSelector } from 'src/redux/store';
import { organizationDropDownAction } from 'src/redux/actions/flagAction';
import { useDispatch } from 'react-redux';
import allActions from '../../../redux/actions';
import { UserViewModel } from '../../../pages/HomePage/viewModels/UserViewModel';
import { localStorageService } from '../../../services/localStorageService';
import { OrganizationAccountsCacheData } from '../../../types/sta';
import { ORGANIZATION_ACCOUNTS_DATA } from '../../../constants/storageKeys';
import { HCHeadingFour } from '../../../components/shared/HypercareComponents';
import theme from '../../../assets/styles/theme';
import { SwitchAccountsDropdownItems } from './AccountSwitchDropdownItems';
import Chevron from '../../../assets/svgs/Chevron';
import { getCurrentLoggedInAccount } from '../../../utils/sta/util';
import { Link } from 'react-router-dom';
import hypercareLogo from 'src/assets/static/hypercare-icon.svg';
import { AccountSwitchLogo, AccountSwitchLogoWrapper, DropdownMenu, DropdownToggler } from '../styled/login.styled';

interface OrganizationSwitcherProps {
  currentOrganization: UserOrganizationSwitcherPayload;
  isOpen: boolean;
}

const AccountSwitchDropdownContainer = ({ currentOrganization, isOpen }: OrganizationSwitcherProps) => {
  const node = React.useRef(null);
  const dispatch = useDispatch();

  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const currentLoggedInAccount = getCurrentLoggedInAccount();

  const { getOrganizationScopeList } = UserViewModel();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const userOrganization = typedUseSelector((store) => store.userOrganizationReducer.organizations);

  const setOpen = () => {
    dispatch(organizationDropDownAction(isOpen ? false : true));
    getUserOrganizations();
  };

  React.useEffect(() => {
    const handleClick = (e) => {
      const modal = document.querySelector('.MuiDialog-container') as HTMLElement;
      const isVisible = !!(modal?.offsetWidth || modal?.offsetHeight || modal?.getClientRects().length);

      if ((node.current && node.current.contains(e.target)) || isVisible) return;
      dispatch(organizationDropDownAction(false));
    };
    if (isOpen) document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [dispatch, isOpen]);

  const getUserOrganizations = async () => {
    if (!userOrganization) {
      const result = await getOrganizationScopeList();
      if (result) {
        dispatch(allActions.userOrganizationAction.setUserOrganizations(result.me.organizations));
      }
    }
  };

  React.useEffect(() => {
    if (isOpen) AnalyticsManager.recordPageVisited(PAGE_VIEWS.switchOrganizationView);
  }, [isOpen]);

  if (currentOrganization.organization_id && currentLoggedInAccount) {
    return (
      <DropdownToggler ref={node} title={`${currentOrganization.organization_id}`}>
        <div
          ref={setReferenceElement}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px', paddingLeft: '8px' }}
        >
          <AccountSwitchLogoWrapper>
            <Link to="/home">
              <AccountSwitchLogo src={hypercareLogo} />
            </Link>
          </AccountSwitchLogoWrapper>
          <div
            onClick={() => setOpen()}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', paddingLeft: '8px' }}
          >
            <HCHeadingFour lineHeight="24px" color={theme.black}>
              {currentOrganization.name}
            </HCHeadingFour>
            <Chevron />
          </div>
        </div>
        {isOpen && (
          <DropdownMenu>
            <SwitchAccountsDropdownItems setOpen={setOpen} currentActiveAccount={currentLoggedInAccount} />
          </DropdownMenu>
        )}
      </DropdownToggler>
    );
  }
  return null;
};

const mapStateToProps = (state: RootState) => {
  return {
    currentOrganization: state.organizationReducer,
    isOpen: state.flagReducer.isOpen,
  };
};

export default connect(mapStateToProps)(AccountSwitchDropdownContainer);
